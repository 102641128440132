import React from "react";
import CustomizedStepper from "./CustomizedStepper";

const Working = () => {
  return (
    <div className="py-32 handle-overflow">
      <div
        className="txt-align-center fs-32 fw-600 neutral-9"
        style={{ color: "black" }}
      >
        How It Works
      </div>
      <CustomizedStepper className="mt-64" />
    </div>
  );
};

export default Working;
