import React from "react";
import { useStateValues } from "./useStateValues";
import CustomModal from "../../common/CustomModal";
import AddTransport from "../AddTransport";
import AddLoad from "../AddLoad";
import AddAddress from "../AddAddress";
import AddReview from "../AddReview";
import AddSalesLead from "../AddSalesLead";
import AddTransportCall from "../AddTransportCall";
import AddTrip from "../AddTrip";

const AddEntity = () => {
  const { transport, load, address, review, sales_lead, call, trip, updateShow } =
    useStateValues();
  return (
    <div>
      {call && (
        <CustomModal
          className="bg-neutral-1"
          width={400}
          open={call}
          onClose={() => updateShow("call", false)}
        >
          <AddTransportCall onClose={() => updateShow("call", false)} />
        </CustomModal>
      )}
      {trip && (
        <CustomModal
          className="bg-neutral-1"
          width={400}
          open={trip}
          onClose={() => updateShow("trip", false)}
        >
          <AddTrip onClose={() => updateShow("trip", false)} />
        </CustomModal>
      )}
      {load && (
        <CustomModal
          className="bg-neutral-1"
          width={400}
          open={load}
          onClose={() => updateShow("load", false)}
        >
          <AddLoad onClose={() => updateShow("load", false)} />
        </CustomModal>
      )}
      {transport && (
        <CustomModal
          className="bg-neutral-1"
          width={400}
          open={transport}
          onClose={() => updateShow("transport", false)}
        >
          <AddTransport onClose={() => updateShow("transport", false)} />
        </CustomModal>
      )}      
      {sales_lead && (
        <CustomModal
          className="bg-neutral-1"
          width={400}
          open={sales_lead}
          onClose={() => updateShow("sales_lead", false)}
        >
          <AddSalesLead onClose={() => updateShow("sales_lead", false)} />
        </CustomModal>
      )}
      {address && (
        <CustomModal
          className="bg-neutral-1"
          width={400}
          open={address}
          onClose={() => updateShow("address", false)}
        >
          <AddAddress onClose={() => updateShow("address", false)} />
        </CustomModal>
      )}
      {review && (
        <CustomModal
          className="bg-neutral-1"
          width={400}
          open={review}
          onClose={() => updateShow("review", false)}
        >
          <AddReview onClose={() => updateShow("review", false)} />
        </CustomModal>
      )}      
    </div>
  );
};

export default AddEntity;
