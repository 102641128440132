import { useCallback, useEffect, useState } from "react";
import { Map, TransportCall } from "../../types";
import {
  UpdateEntityDebounceIndexFn,
  UpdateEntityFn,
} from "../../common/AddTransport/types";
import { listTransportCallApi, updateTransportCallApi } from "./api";
import { debounce } from "../../util/debounce";

export const useStateValues = () => {
  const [transport_call, setTarnsportCall] = useState<TransportCall[]>([]);
  const [page, setPage] = useState(0);
  const [page_size, setPageSize] = useState(50);
  const [count, setCount] = useState(0);
  const [order_by, setOrderBy] = useState("-created");
  const [filter, setFilter] = useState<Map>({});
  const updateFilter: UpdateEntityFn<Map> = useCallback(
    (key, value) => {
      const newFilters = { ...filter };
      newFilters[key] = value;
      setFilter(newFilters);
    },
    [filter]
  );
  const getTransportCall = useCallback(() => {
    listTransportCallApi({
      ...filter,
      page: page + 1,
      page_size,
      order_by,
    }).then((data) => {
      setTarnsportCall(data.results);
      setCount(data.count);
    });
  }, [filter, order_by, page, page_size]);
  useEffect(() => {
    getTransportCall();
  }, [filter, getTransportCall]);
  
  const updateTransportCall: UpdateEntityDebounceIndexFn<TransportCall> = ({
    index,
    key,
    value,
  }) => {
    const newTransportCall = [...transport_call];
    newTransportCall[index][key] = value;
    setTarnsportCall(newTransportCall);
    updateTransportCallApi(newTransportCall[index].id as number, {
      [key]: value,
    });
  }; 

  const updateSortKey = (sort_key: string) => {
    if (sort_key?.length) {
      if (order_by.includes(sort_key)) {
        if (order_by === sort_key) {
          setOrderBy(`-${sort_key}`);
        } else {
          setOrderBy(sort_key);
        }
      } else {
        setOrderBy(sort_key);
      }
    }
  };
  return {
    transport_call,
    setTarnsportCall,
    filter,
    updateFilter,
    page,
    setPage,
    page_size,
    setPageSize,
    count,
    updateTransportCall: debounce(updateTransportCall),
    order_by,
    updateSortKey,
  };
};
