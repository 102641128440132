import { useCallback, useEffect, useState } from "react";
import { BlackList, Map, Transport, TransportReview } from "../../types";
import {
  UpdateEntityDebounceIndexFn,
  UpdateEntityFn,
} from "../../common/AddTransport/types";
import {
  createBlacklistApi,
  listReviewApi,
  updateBlacklistApi,
  updateReviewApi,
} from "./api";
import { debounce } from "../../util/debounce";

export const useStateValues = () => {
  const [reviews, setReviews] = useState<TransportReview[]>([]);
  const [page, setPage] = useState(0);
  const [page_size, setPageSize] = useState(50);
  const [count, setCount] = useState(0);
  const [order_by, setOrderBy] = useState("-created");
  const [filter, setFilter] = useState<Map>({});
  const updateFilter: UpdateEntityFn<Map> = useCallback(
    (key, value) => {
      const newFilters = { ...filter };
      newFilters[key] = value;
      setFilter(newFilters);
    },
    [filter]
  );
  const getTransporters = useCallback(() => {
    listReviewApi({
      ...filter,
      page: page + 1,
      page_size,
      order_by,
    }).then((data) => {
      setReviews(data.results);
      setCount(data.count);
    });
  }, [filter, order_by, page, page_size]);
  useEffect(() => {
    getTransporters();
  }, [filter, getTransporters]);
  const updateReview: UpdateEntityDebounceIndexFn<TransportReview> = ({
    index,
    key,
    value,
  }) => {
    const newReviews = [...reviews];
    newReviews[index][key] = value;
    setReviews(newReviews);
    updateReviewApi(reviews[index].id as number, {
      [key]: value,
    });
  };

  const updateBlacklist: UpdateEntityDebounceIndexFn<BlackList> = ({
    index,
    key,
    value,
  }) => {
    const newReviews = [...reviews];
    const review = newReviews[index];
    if (review.blacklist) {
      review.blacklist[key] = value;
      setReviews(newReviews);
      updateBlacklistApi(review.blacklist?.id as number, {
        [key]: value,
      });
    } else {
      review.blacklist = {
        transport: (review.transport as Transport)!.id as number,
        total_fraud: 0,
        is_public: true,
        [key]: value,
      };
      createBlacklistApi(review.blacklist).then((data) => {
        const newReviews = [...reviews];
        const review = newReviews[index];
        review.blacklist = data;
        setReviews(newReviews);
      });
    }
  };

  const updateSortKey = (sort_key: string) => {
    if (sort_key?.length) {
      if (order_by.includes(sort_key)) {
        if (order_by === sort_key) {
          setOrderBy(`-${sort_key}`);
        } else {
          setOrderBy(sort_key);
        }
      } else {
        setOrderBy(sort_key);
      }
    }
  };
  return {
    reviews,
    setReviews,
    filter,
    updateFilter,
    page,
    setPage,
    page_size,
    setPageSize,
    count,
    updateReview: debounce(updateReview),
    updateBlacklist: updateBlacklist,
    order_by,
    updateSortKey,
  };
};
