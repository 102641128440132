import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useStateValues } from "./useStateValue";
import CustomAutoComplete from "../../common/CustomAutoComplete";
import { Address, Data, Transport, Trip, User } from "../../types";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { capitalizeFirstLetter } from "../../util/utils";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import UserAvatar from "../../common/UserAvatar";
import { CustomAutoCompleteSelected } from "../../common/CustomAutoComplete/types";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TablePagination,
  TextField,
} from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { searchTranportApi } from "../TransportDashboard/api";
import { setAddEntity } from "../../../redux/slices/entitySlice";
import { useDispatch } from "react-redux";
import { filterAddressApi } from "../AddAddress/api";
import "./index.scss";
import { filterUserApi } from "../User/api";
import { TRIP_STATUS, TripStatus } from "./types";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
const TripDashboard = () => {
  const dispatch = useDispatch();
  const {
    trips,
    filter,
    updateFilter,
    page,
    setPage,
    page_size,
    setPageSize,
    count,
    updateTrip,
    order_by,
    updateSortKey,
  } = useStateValues();

  const columns = [
    {
      name: "Id",
      sort_key: "id",
      width: "100px",
      fn: (trip: Trip, index: number) => <TableCell>{trip.id}</TableCell>,
    },
    {
      name: "Start Date",
      sort_key: "start_date",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              sx={{ width: "300px" }}
              label="Select Date & Time"
              value={dayjs(trip.start_date)}
              onChange={(date) => {
                if (date) {
                  updateTrip({
                    index,
                    key: "start_date",
                    value: date.toISOString(),
                  });
                }
              }}
            />
          </LocalizationProvider>
        </TableCell>
      ),
    },
    {
      name: "End Date",
      sort_key: "end_date",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              sx={{ width: "300px" }}
              label="Select Date & Time"
              value={dayjs(trip.end_date)}
              onChange={(date) => {
                if (date) {
                  updateTrip({
                    index,
                    key: "end_date",
                    value: date.toISOString(),
                  });
                }
              }}
            />
          </LocalizationProvider>
        </TableCell>
      ),
    },
    {
      name: "Vehicle",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <TextField
            //
            size="small"
            style={{ width: 200 }}
            className=" bg-neutral-2 "
            placeholder="Vehicle"
            variant="outlined"
            defaultValue={trip.vehicle}
            onChange={(e) =>
              updateTrip({
                index,
                key: "vehicle",
                value: e.target.value,
              })
            }
          />
        </TableCell>
      ),
    },
    {
      name: "Start Odometer",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <TextField
            //
            size="small"
            style={{ width: 200 }}
            className=" bg-neutral-2 "
            placeholder="Start Odometer"
            variant="outlined"
            defaultValue={trip.start_odometer}
            onChange={(e) =>
              updateTrip({
                index,
                key: "start_odometer",
                value: Number(e.target.value),
              })
            }
          />
        </TableCell>
      ),
    },
    {
      name: "Start Fuel",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <TextField
            //
            size="small"
            style={{ width: 200 }}
            className=" bg-neutral-2 "
            placeholder="Start Fuel"
            variant="outlined"
            defaultValue={trip.start_fuel}
            onChange={(e) =>
              updateTrip({
                index,
                key: "start_fuel",
                value: Number(e.target.value),
              })
            }
          />
        </TableCell>
      ),
    },    
    {
      name: "End Odometer",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <TextField
            //
            size="small"
            style={{ width: 200 }}
            className=" bg-neutral-2 "
            placeholder="End Odometer"
            variant="outlined"
            defaultValue={trip.end_odometer}
            onChange={(e) =>
              updateTrip({
                index,
                key: "end_odometer",
                value: Number(e.target.value),
              })
            }
          />
        </TableCell>
      ),
    },
    {
      name: "End Fuel",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <TextField
            //
            size="small"
            style={{ width: 200 }}
            className=" bg-neutral-2 "
            placeholder="End Fuel"
            variant="outlined"
            defaultValue={trip.end_fuel}
            onChange={(e) =>
              updateTrip({
                index,
                key: "end_fuel",
                value: Number(e.target.value),
              })
            }
          />
        </TableCell>
      ),
    },
    {
      name: "Transport",
      sort_key: "",
      width: "300px",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <CustomAutoComplete
            className=""
            width={"250px"}
            defaultValue={trip.transport as Transport}
            onSelect={(transport: Transport | Transport[] | null) => {
              if (transport) {
                updateTrip({
                  index,
                  key: "transport",
                  value: (transport as Transport).id as number,
                });
              } else {
                updateFilter("transport", null);
              }
            }}
            getOptionLabel={(load: Transport) => {
              return capitalizeFirstLetter(load.name);
            }}
            placeholder="Transport"
            renderOptionFn={(load: Transport) => (
              <UserAvatar user={{ first_name: load.name }} />
            )}
            dataSource={(text: string) => searchTranportApi({ name: text })}
          />
        </TableCell>
      ),
    },
    {
      name: "Phone",
      sort_key: "",
      width: "300px",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          {(trip.transport as Transport)?.phone}
        </TableCell>
      ),
    },
    {
      name: "Added By",
      sort_key: "",
      width: "300px",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <UserAvatar user={trip.added_by as User} />
        </TableCell>
      ),
    },
    {
      name: "Origin",
      sort_key: "origin.city",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <CustomAutoComplete
            className=""
            width={"200px"}
            defaultValue={trip.origin as Address}
            onSelect={(address: CustomAutoCompleteSelected<Address>) => {
              if (address) {
                updateTrip({
                  index,
                  key: "origin",
                  value: (address as Address).id as number,
                });
              }
            }}
            getOptionLabel={(address: Address) => {
              return `${address.city}, ${address.state}`;
            }}
            placeholder="Origin"
            renderOptionFn={(address: Address) => (
              <Box>{`${address.city}, ${address.state}`}</Box>
            )}
            dataSource={(text: string) => filterAddressApi({ text })}
          />
        </TableCell>
      ),
    },
    {
      name: "Destination",
      sort_key: "destination.city",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <CustomAutoComplete
            className=""
            width={"200px"}
            defaultValue={trip.destination as Address}
            onSelect={(address: CustomAutoCompleteSelected<Address>) => {
              if (address) {
                updateTrip({
                  index,
                  key: "destination",
                  value: (address as Address).id as number,
                });
              }
            }}
            getOptionLabel={(address: Address) => {
              return `${address.city}, ${address.state}`;
            }}
            placeholder="Destination"
            renderOptionFn={(address: Address) => (
              <Box>{`${address.city}, ${address.state}`}</Box>
            )}
            dataSource={(text: string) => filterAddressApi({ text })}
          />
        </TableCell>
      ),
    },
    {
      name: "Status",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <CustomAutoComplete
            className=""
            width={"200px"}
            defaultValue={{ id: trip.status }}
            onSelect={(status: CustomAutoCompleteSelected<Data>) =>
              updateTrip({
                index,
                key: "status",
                value: (status as TripStatus).id as string,
              })
            }
            getOptionLabel={(status: TripStatus) => status.id as string}
            placeholder="Status"
            renderOptionFn={(status: TripStatus) => <div>{status.id}</div>}
            dataList={TRIP_STATUS}
          />
        </TableCell>
      ),
    },
    {
      name: "POD",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={trip.pod as boolean}
                onChange={(e) =>
                  updateTrip({
                    index,
                    key: "pod",
                    value: e.target.checked,
                  })
                }
              />
            }
            label=""
          />
        </FormGroup>
      ),
    },
    {
      name: "Payment Pending",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          {((trip.amount_total || 0) - (trip.payment_complete || 0)).toFixed(0)}
        </TableCell>
      ),
    },
    {
      name: "Rate Ton",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <TextField
            style={{ width: 100 }}
            size="small"
            className=" bg-neutral-2 "
            placeholder="Amount/Ton"
            variant="outlined"
            type="number"
            defaultValue={trip.amount_ton}
            onChange={(e) =>
              updateTrip({
                index,
                key: "amount_ton",
                value: Number(e.target.value),
              })
            }
          />
        </TableCell>
      ),
    },
    {
      name: "Total Rate",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <TextField
            style={{ width: 100 }}
            size="small"
            className=" bg-neutral-2 "
            placeholder="Amount Total"
            variant="outlined"
            type="number"
            defaultValue={trip.amount_total}
            onChange={(e) =>
              updateTrip({
                index,
                key: "amount_total",
                value: Number(e.target.value),
              })
            }
          />
        </TableCell>
      ),
    },
    {
      name: "Payment Complete",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <TextField
            style={{ width: 100 }}
            size="small"
            className=" bg-neutral-2 "
            placeholder="Payment Complete"
            variant="outlined"
            type="number"
            defaultValue={trip.payment_complete}
            onChange={(e) =>
              updateTrip({
                index,
                key: "payment_complete",
                value: Number(e.target.value),
              })
            }
          />
        </TableCell>
      ),
    },
    {
      name: "Payment Lapse",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <TextField
            style={{ width: 100 }}
            size="small"
            className=" bg-neutral-2 "
            placeholder="Payment Lapse"
            variant="outlined"
            type="number"
            defaultValue={trip.payment_lapse}
            onChange={(e) =>
              updateTrip({
                index,
                key: "payment_lapse",
                value: Number(e.target.value),
              })
            }
          />
        </TableCell>
      ),
    },
    {
      name: "Distance",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <TextField
            style={{ width: 100 }}
            size="small"
            className=" bg-neutral-2 "
            placeholder="Distance"
            variant="outlined"
            type="text"
            defaultValue={trip.distance}
            onChange={(e) =>
              updateTrip({
                index,
                key: "distance",
                value: Number(e.target.value),
              })
            }
          />
        </TableCell>
      ),
    },
    {
      name: "Days",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <TextField
            style={{ width: 100 }}
            size="small"
            className=" bg-neutral-2 "
            placeholder="Distance"
            variant="outlined"
            type="text"
            defaultValue={trip.days}
            onChange={(e) =>
              updateTrip({
                index,
                key: "days",
                value: Number(e.target.value),
              })
            }
          />
        </TableCell>
      ),
    },
    {
      name: "Total Distance",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <TextField
            style={{ width: 100 }}
            size="small"
            className=" bg-neutral-2 "
            placeholder="Distance"
            variant="outlined"
            type="text"
            defaultValue={trip.total_distance}
            onChange={(e) =>
              updateTrip({
                index,
                key: "total_distance",
                value: Number(e.target.value),
              })
            }
          />
        </TableCell>
      ),
    },
    {
      name: "Min Guarentee",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <TextField
            style={{ width: 100 }}
            size="small"
            className=" bg-neutral-2 "
            placeholder="Min Guarentee"
            variant="outlined"
            type="text"
            defaultValue={trip.min_guarentee}
            onChange={(e) =>
              updateTrip({
                index,
                key: "min_guarentee",
                value: Number(e.target.value),
              })
            }
          />
        </TableCell>
      ),
    },
    {
      name: "Comment",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <TextField
            style={{ width: 200 }}
            size="small"
            className=" bg-neutral-2 "
            placeholder="Comment"
            variant="outlined"
            defaultValue={trip.comment}
            onChange={(e) =>
              updateTrip({
                index,
                key: "comment",
                value: e.target.value,
              })
            }
          />
        </TableCell>
      ),
    },
    {
      name: "Driver Expense",
      sort_key: "",
      width: "200px",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <TextField
            size="small"
            className=" bg-neutral-2 "
            placeholder="Driver Expense"
            variant="outlined"
            defaultValue={trip.driver_expense}
            onChange={(e) =>
              updateTrip({
                index,
                key: "driver_expense",
                value: Number(e.target.value),
              })
            }
          />
        </TableCell>
      ),
    },
    {
      name: "Toll Tax",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <TextField
            style={{ width: 100 }}
            size="small"
            className=" bg-neutral-2 "
            placeholder="Driver Expense"
            variant="outlined"
            defaultValue={trip.toll_tax}
            onChange={(e) =>
              updateTrip({
                index,
                key: "toll_tax",
                value: Number(e.target.value),
              })
            }
          />
        </TableCell>
      ),
    },
    {
      name: "Driver Payment",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <TextField
            style={{ width: 100 }}
            size="small"
            className=" bg-neutral-2 "
            placeholder="Driver Payment"
            variant="outlined"
            defaultValue={trip.driver_payment}
            onChange={(e) =>
              updateTrip({
                index,
                key: "driver_payment",
                value: Number(e.target.value),
              })
            }
          />
        </TableCell>
      ),
    },
    {
      name: "Fuel Cost",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <TextField
            style={{ width: 100 }}
            size="small"
            className=" bg-neutral-2 "
            placeholder="Driver Expense"
            variant="outlined"
            defaultValue={trip.fuel_cost}
            onChange={(e) =>
              updateTrip({
                index,
                key: "fuel_cost",
                value: Number(e.target.value),
              })
            }
          />
        </TableCell>
      ),
    },
    {
      name: "Other Cost",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <TextField
            style={{ width: 100 }}
            size="small"
            className=" bg-neutral-2 "
            placeholder="Driver Expense"
            variant="outlined"
            defaultValue={trip.other_cost}
            onChange={(e) =>
              updateTrip({
                index,
                key: "other_cost",
                value: Number(e.target.value),
              })
            }
          />
        </TableCell>
      ),
    },
    {
      name: "Cash Payment",
      sort_key: "",
      fn: (trip: Trip, index: number) => (
        <TableCell align="left">
          <TextField
            style={{ width: 100 }}
            size="small"
            className=" bg-neutral-2 "
            placeholder="Driver Expense"
            variant="outlined"
            defaultValue={trip.cash_payment}
            onChange={(e) =>
              updateTrip({
                index,
                key: "cash_payment",
                value: Number(e.target.value),
              })
            }
          />
        </TableCell>
      ),
    },
  ];

  function getBackgroundColor(trip: Trip) {
    const payment_complete = trip.payment_complete || 0;
    const amount_total = trip.amount_total || 0;
    const balance = amount_total - payment_complete;
    if (trip.status !== "COMPLETE") {
      if (balance > 0) {
        return "rgba(255, 255, 0, 0.2)";
      } else {
        return "rgba(0, 255, 0, 0.2)";
      }
    }
    if (balance > 0) {
      return "rgba(255, 0, 0, 0.2)";
    } else {
      return "rgba(0, 255, 0, 0.2)";
    }
  }

  return (
    <div className="p-8">
      <div className="d-flex gap-8 flex-wrap">
        <CustomAutoComplete
          className=""
          width={"200px"}
          onSelect={(transport: Transport | Transport[] | null) => {
            if (transport) {
              updateFilter("transport_id", (transport as Transport).id);
            } else {
              updateFilter("transport_id", null);
            }
          }}
          getOptionLabel={(load: Transport) => {
            return capitalizeFirstLetter(load.name);
          }}
          placeholder="Transport"
          renderOptionFn={(load: Transport) => (
            <UserAvatar user={{ first_name: load.name }} />
          )}
          dataSource={(text: string) => searchTranportApi({ name: text })}
        />
        <TextField
          size="small"
          className="bg-neutral-2 "
          placeholder="Destination City"
          variant="outlined"
          onChange={(e) => updateFilter("origin_city", e.target.value)}
        />
        <TextField
          size="small"
          className="bg-neutral-2 "
          placeholder="Destination City"
          variant="outlined"
          onChange={(e) => updateFilter("destination_city", e.target.value)}
        />
        <CustomAutoComplete
          className=""
          width={"200px"}
          onSelect={(user: CustomAutoCompleteSelected<User>) => {
            if (user) {
              updateFilter("added_by_id", (user as User)?.id);
            } else {
              updateFilter("added_by_id", null);
            }
          }}
          getOptionLabel={(user: User) =>
            `${user.first_name} ${user.last_name}`
          }
          placeholder="Added By"
          renderOptionFn={(user: User) => (
            <div>
              {user.first_name} {user.last_name}
            </div>
          )}
          dataSource={(text: string) => filterUserApi({ text })}
        />
        <TextField
          size="small"
          className=" bg-neutral-2 "
          placeholder="Vehicle"
          variant="outlined"
          onChange={(e) => updateFilter("vehicle", e.target.value)}
        />
        <CustomAutoComplete
          multiple={true}
          defaultValue={[{ id: "TRANSIT" }, { id: "SCHEDULED" }]}
          width={"200px"}
          onSelect={(status: CustomAutoCompleteSelected<TripStatus>) => {
            if (status) {
              updateFilter(
                "status",
                (status as TripStatus[]).map((s) => s.id).join(",")
              );
            } else {
              updateFilter("status", null);
            }
          }}
          getOptionLabel={(status: TripStatus) => status.id as string}
          placeholder="Status"
          renderOptionFn={(status: TripStatus) => <div>{status.id}</div>}
          dataList={TRIP_STATUS}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={filter.due_balance as boolean}
                onChange={(e) => updateFilter("due_balance", e.target.checked)}
              />
            }
            label="Due Balance"
          />
        </FormGroup>
      </div>
      <div className="mt-8">
        <TableContainer
          sx={{ backgroundColor: "white", width: "95vw" }}
          component={Paper}
        >
          <div className="d-flex gap-16 flex-wrap align-items-center px-16">
            <PlaylistAddIcon
              onClick={() =>
                dispatch(setAddEntity({ key: "trip", value: true }))
              }
              className="orange-3 pointer"
            />
            <TablePagination
              rowsPerPageOptions={trips.length ? [10, 20, 50] : []}
              component="div"
              count={count}
              rowsPerPage={page_size}
              page={page}
              onPageChange={(_, page) => setPage(page)}
              onRowsPerPageChange={(e) =>
                setPageSize(parseInt(e.target.value, 10))
              }
            />
          </div>
          <Table size="small" className="load-table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.name}
                    align="left"
                    width={column.width || "100px"}
                  >
                    <div
                      className="d-flex gap-8 align-items-center pointer"
                      onClick={(e) => {
                        updateSortKey(column.sort_key);
                      }}
                    >
                      <span>{column.name}</span>
                      {column.sort_key.length &&
                      order_by.includes(column.sort_key) ? (
                        <span>
                          <SwapVertIcon />
                        </span>
                      ) : null}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {trips.map((trip, index) => (
                <React.Fragment key={trip.id}>
                  <TableRow
                    key={trip.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor: getBackgroundColor(trip),
                    }}
                  >
                    {columns.map((column) => column.fn(trip, index))}
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default TripDashboard;
