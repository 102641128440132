import { axiosInstance } from "../../../api";
import {
  Address,
  Load,
  PaginatedResponse,
  Transport,
} from "../../types";

export const listLoadApi = ({
  name,
  id,
  phone,
  origin_city,
  is_public = null,
  added_by_id,
  is_expired,
  destination_city,
  rating,  
  order_by = "created",
  page = 1,
  page_size = 5,
}: {
  name?: string;
  id?: number;
  phone?: string;
  is_expired?: boolean;
  added_by_id?: number;
  is_public?: boolean | null;
  origin_city?: string;
  destination_city?: string;
  rating?: number; 
  order_by?: string;
  page?: number;
  page_size?: number;
}) => {
  const urlParams = new URLSearchParams();
  urlParams.set("order_by", order_by);
  urlParams.set("page", String(page));
  urlParams.set("limit", String(page_size));

  if (phone) {
    urlParams.set("phone", phone);
  }
  if (id) {
    urlParams.set("id", String(id));
  }
  if (origin_city) {
    urlParams.set("origin_city", origin_city);
  }
  if (destination_city) {
    urlParams.set("destination_city", destination_city);
  }  
  if (rating) {
    urlParams.set("rating", String(rating));
  }
  if (name) {
    urlParams.set("name", name);
  }
  if (added_by_id) {
    urlParams.set("added_by_id", String(added_by_id));
  }
  if(is_public !== null) {
    urlParams.set('is_public', String(is_public));
  }
  urlParams.set("is_expired", String(is_expired));
  return axiosInstance()
    .get<PaginatedResponse<Load>>(
      `/backoffice/load/list-detail/?page=1&${urlParams.toString()}`
    )
    .then((response) => response.data);
};

export const filterLoadCityApi = ({
  city,
  page = 1,
  page_size = 5,
}: {
  city?: string;
  page?: number;
  page_size?: number;
}) => {
  const urlParams = new URLSearchParams();
  if (city) {
    urlParams.set("city", city);
  }
  urlParams.set("page", String(page));
  urlParams.set("limit", String(page_size));
  return axiosInstance()
    .get<PaginatedResponse<Address>>(
      `/backoffice/transport/city/?page=1&${urlParams.toString()}`
    )
    .then((response) => response.data);
};

export const updateLoadApi = (
  tranport_id: number,
  data: { [key: string]: unknown }
) => {
  return axiosInstance()
    .patch<Transport>(`/backoffice/load/${tranport_id}/`, data)
    .then((response) => response.data);
};


export const updateAddressApi = (
  address_id: number,
  data: { [key: string]: string }
) => {
  return axiosInstance()
    .patch<Address>(`/backoffice/address/${address_id}/`, data)
    .then((response) => response.data);
};

export const filterLoadStateApi = ({
  state,
  page = 1,
  page_size = 5,
}: {
  state?: string;
  page?: number;
  page_size?: number;
}) => {
  const urlParams = new URLSearchParams();
  if (state) {
    urlParams.set("state", state);
  }
  urlParams.set("page", String(page));
  urlParams.set("limit", String(page_size));
  return axiosInstance()
    .get<PaginatedResponse<Address>>(
      `/backoffice/transport/state/?page=1&${urlParams.toString()}`
    )
    .then((response) => response.data);
};
