import { Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { axiosInstance } from "../../../api";
import { useDispatch } from "react-redux";
import { Address, Load, Transport } from "../../types";
import { setMessage } from "../../../redux/slices/alertSlice";
import CustomAutoComplete from "../../common/CustomAutoComplete";
import { capitalizeFirstLetter } from "../../util/utils";
import UserAvatar from "../../common/UserAvatar";
import { CustomAutoCompleteSelected } from "../../common/CustomAutoComplete/types";
import { TablePagination, TextField } from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { setAddEntity } from "../../../redux/slices/entitySlice";
import { searchTranportApi } from "../TransportDashboard/api";
import { filterAddressApi } from "../AddAddress/api";
import { Add, PlusOne } from "@mui/icons-material";
import { useStateValues } from "./useStateValues";

export const AddAddress = ({ onClose }: { onClose?: () => void }) => {
  const dispatch = useDispatch();

  const { address, updateAddress, errorConfig, validate } = useStateValues();

  const handleAddAddress = async () => {
    if (validate()) {
      return;
    }
    const data = {
      ...address,
    };
    try {
      await axiosInstance().post<Load>("backoffice/address/", data);
      dispatch(setMessage("Address Added"));
      onClose?.();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <div className="bg-neutral-3 d-flex p-16 align-items-center">
        <div className="fs-18 flex-grow">Add Load</div>
        <div className="pointer" onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      <div className="p-16 overflow-y-scroll" style={{ height: "70vh" }}>
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Address"
          variant="outlined"
          onChange={(e) => updateAddress("address_1", e.target.value)}
          helperText={errorConfig["address_1"]}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="City"
          variant="outlined"
          onChange={(e) => updateAddress("city", e.target.value)}
          helperText={errorConfig["city"]}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="State"
          variant="outlined"
          onChange={(e) => updateAddress("state", e.target.value)}
          helperText={errorConfig["state"]}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Pincode"
          variant="outlined"
          onChange={(e) => updateAddress("pincode", e.target.value)}
          helperText={errorConfig["pincode"]}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Latitude"
          variant="outlined"
          onChange={(e) => updateAddress("latitude", Number(e.target.value))}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Longitude"
          variant="outlined"
          onChange={(e) => updateAddress("longitude", Number(e.target.value))}
        />
      </div>
      <div className="p-16 mt-16">
        <div className="d-flex gap-8 justify-content-end">
          <Button
            className="flex-half"
            color="primary"
            variant="contained"
            onClick={() => onClose?.()}
          >
            Cancel
          </Button>
          <Button
            className="flex-half"
            color="primary"
            variant="contained"
            onClick={handleAddAddress}
          >
            Add Address
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddAddress;
