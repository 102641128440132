import React, { useEffect, useRef, useState } from "react";
import logo from "../../../assets/logo.png";
import "./index.scss";
import { debounce } from "../../util/debounce";
import { useNavigate } from "react-router-dom";
import Login from "../Login";
import CustomModal from "../CustomModal";
import { logout, showLogin, useUser } from "../../../redux/slices/userSlice";
import { isEmptyString } from "../../util/utils";
import { useDispatch } from "react-redux";
import CustomAlert from "../CustomAlert";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import UserAvatar from "../UserAvatar";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userSlice = useUser();
  const headerRef = useRef<HTMLDivElement>(null);
  const onScroll = () => {
    if (headerRef && headerRef.current) {
      headerRef.current.dataset.scroll = String(window.scrollY);
    }
  };

  useEffect(() => {
    const listener = debounce(onScroll);
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  return (
    <div
      data-scroll="0"
      ref={headerRef}
      onScroll={debounce(onScroll)}
      className="header-outer d-flex align-items-center py-8"
    >
      <div
        onClick={() => navigate("/")}
        style={{ flexBasis: "20%" }}
        className="d-flex justify-content-center"
      >
        <img src={logo} alt="Website Logo" className="website-logo pointer" />
      </div>
      <div className="header-nav-option flex-grow d-flex gap-24 justify-content-start d-md-none neutral-1 display-md-hidden-down">
        <div className="pointer white-space-nowrap" onClick={() => navigate('/load-search')}>LOAD SEARCH</div>
        {/* <div className="pointer white-space-nowrap">LOAD VALIDATE</div> */}
        <div
          className="pointer white-space-nowrap"
          onClick={() => navigate("/submit-review")}
        >
          REVIEW
        </div>
      </div>
      <div className="d-flex px-16 gap-16 display-md-hidden-down">
        {isEmptyString(userSlice.token) ? (
          <div
            className=""
            style={{ flexBasis: "200px" }}
            onClick={() => dispatch(showLogin(true))}
          >
            <div className="rounded-32 elevation-2 pointer fw-500 p-8 bg-orange-4 neutral-1 txt-align-center">
              Login
            </div>
          </div>
        ) : (
          <div className="d-flex gap-8">
            <UserAvatar user={userSlice.user!}/>
            {userSlice.user?.is_staff ? (
              <div
                className="rounded-32 elevation-2 pointer fw-500 p-16 bg-orange-4 neutral-1 txt-align-center"
                onClick={() => navigate("/admin")}
              >
                Admin
              </div>
            ) : null}

            <div
              className="rounded-32 elevation-2 pointer fw-500 p-16 bg-orange-4 neutral-1 txt-align-center"
              onClick={() => dispatch(logout())}
            >
              Logout
            </div>
          </div>
        )}
      </div>
      <div className="display-none display-md-block-down w-100">
        <div className="w-100 txt-align-right">
          <Button
            id="basic-button"
            aria-controls={menuOpen ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? "true" : undefined}
            onClick={(e) => {
              setMenuOpen(true);
            }}
            ref={menuRef}
          >
            <MoreVertIcon className="header-nav-icon neutral-1" />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={menuRef.current}
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => navigate('/load-search')}>Load Search</MenuItem>
            {/* <MenuItem onClick={() => {}}>Load Validate</MenuItem> */}
            <MenuItem
              onClick={() => {
                navigate("/submit-review");
                setMenuOpen(false);
              }}
            >
              Review
            </MenuItem>
            {isEmptyString(userSlice.token) ? (
              <MenuItem
                onClick={() => {
                  dispatch(showLogin(true));
                  setMenuOpen(false);
                }}
              >
                Login
              </MenuItem>
            ) : (
              <React.Fragment>
                {userSlice.user?.is_staff ? (
                  <MenuItem
                    onClick={() => {
                      navigate("/admin");
                      setMenuOpen(false);
                    }}
                  >
                    Admin
                  </MenuItem>
                ) : null}
                <MenuItem
                  onClick={() => {
                    dispatch(logout());
                    setMenuOpen(false);
                  }}
                >
                  Logout
                </MenuItem>
              </React.Fragment>
            )}
          </Menu>
        </div>
      </div>
      {userSlice.showLogin && (
        <CustomModal
          className="bg-neutral-1"
          width={400}
          open={userSlice.showLogin}
          onClose={() => dispatch(showLogin(false))}
        >
          <Login onClose={() => dispatch(showLogin(false))} view="LOGIN" />
        </CustomModal>
      )}
      <CustomAlert />
    </div>
  );
};

export default Header;
