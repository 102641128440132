import Landing from "./Landing";
import { Routes, Route } from "react-router-dom";
import TransportDashboard from "./TransportDashboard";
import ReviewDashboard from "./ReviewDashboard";
import SalesDashboard from "./SalesDashboard";
import LoadDashboard from "./LoadDashboard";
import AddEntity from "./AddEntity";
import React from "react";
import CallDashboard from "./CallDashboard";
import TripDashboard from "./TripDashboard";

const AdminDashboard = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Landing />}>
          <Route index element={<TransportDashboard />} />
          <Route path="/review" element={<ReviewDashboard />} />
          <Route path="/load" element={<LoadDashboard />} />
          <Route path="/sales" element={<SalesDashboard />} />
          <Route path="/transport-call" element={<CallDashboard />} />
          <Route path="/trip" element={<TripDashboard />} />
        </Route>
      </Routes>
      <AddEntity />
    </React.Fragment>
  );
};

export default AdminDashboard;
