import StarHalfIcon from "@mui/icons-material/StarHalf";
import { UserReview } from "../../types";
import { UserReviewRow } from "./UserReviewRow";
import { useReview } from "../../../redux/slices/reviewSlice";
import { useEffect, useState } from "react";
import { getTransportReview } from "./api";

export const ExistingReview = ({ className }: { className: string }) => {
  // const singleReview: UserReview = {
  //   user: {
  //     first_name: "Sudhanshu",
  //     last_name: "Saxena",
  //     avatar: ""
  //   },
  //   comment: "Test Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment  Comment ",
  //   rating: 3.5,
  //   transport: {
  //     name: "Transprot Info",
  //     email: "Transprot Info",
  //     phone: "Transprot Info"
  //   },
  //   created: '2024-06-25 09:42:58.676135',
  //   modified: '2024-06-25 09:42:58.676135'
  // };
  // const userReviews: UserReview[] = [
  //   singleReview,
  //   singleReview,
  //   singleReview,
  //   singleReview
  // ]

  const [userReviews, setUserReviews] = useState<UserReview[]>([]);

  const review = useReview();
  useEffect(() => {
    if (review?.transport?.id) {
      getTransportReview(review.transport.id as number).then((data) => {
        setUserReviews(data);
      });
    }
  }, [review?.transport]);

  return (
    <div
      style={{
        maxHeight: "70vh",
        overflowY: "scroll",
        minWidth: 300,
        maxWidth: 300,
      }}
      className={`flex-col ${className}`}
    >
      <div
        style={{ borderBottom: "1px solid red" }}
        className="p-8 px-16 d-flex justify-content-end align-items-center gap-8"
      >
        <StarHalfIcon className="orange-4" />
        <div className="fs-14">Existing Reviews</div>
      </div>
      <div className="bg-neutral-1 flex-grow">
        {userReviews.map((userReview, index) => (
          <UserReviewRow row={index} userReview={userReview} key={index} />
        ))}
      </div>
    </div>
  );
};
