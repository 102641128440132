import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { ReduxStore } from "../types";

export interface AlertSlice {
  message: string;
  open: boolean;
  timeout: 3000;
}

const initialState: AlertSlice = {
  message: "",
  open: false,
  timeout: 3000,
};

const alertSlice = createSlice({
  name: "alertSlice",
  initialState,
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload;
      state.open = true;
    },
    setTimeout: (state, action) => {
      state.timeout = action.payload;
    },
    closeAlert: (state) => {
      state.message = "";
      state.open = false;
    },
  },
});

export const { setMessage, closeAlert } = alertSlice.actions;

export default alertSlice.reducer;

export const useAlert: () => AlertSlice = () =>
  useSelector((store: ReduxStore) => store.alert);
