import { Button, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useStateValues } from "./useStateValues";
import { axiosInstance } from "../../../api";
import { useDispatch } from "react-redux";
import { Transport } from "../../types";
import { setMessage } from "../../../redux/slices/alertSlice";

export const AddTransport = ({ onClose }: { onClose?: () => void }) => {
  const dispatch = useDispatch();

  const {
    address,
    updateAddress,
    transport,
    updateTransport,
    errorConfig,
    validate,
  } = useStateValues();

  const handleAddTransport = async () => {
    if (validate()) {
      return;
    }
    const data = {
      user: {
        first_name: transport.name,
        last_name: "",
      },
      address: {
        ...address,
        pincode: "00000",
        address_1: address.city,
        address_2: "",
        country: "IN",
      },
      ...transport,
    };
    try {
      await axiosInstance().post<Transport>("transport/", data);
      dispatch(setMessage("Transport Added"));
      onClose?.();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <div className="bg-neutral-3 d-flex p-16 align-items-center">
        <div className="fs-18 flex-grow">Add Transporter</div>
        <div className="pointer" onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      <div className="p-16 overflow-y-scroll" style={{ height: "70vh" }}>
        <TextField
          fullWidth
          value={transport.name}
          className="mt-8 bg-neutral-2 flex-grow "
          label="Transporter Name"
          variant="outlined"
          onChange={(e) => updateTransport("name", e.target.value)}
          helperText={errorConfig["transport_name"]}
        />
        <TextField
          fullWidth
          value={transport.phone}
          className="mt-8  bg-neutral-2 "
          label="Phone Number"
          variant="outlined"
          type="number"
          onChange={(e) => updateTransport("phone", e.target.value)}
          helperText={errorConfig["transport_phone"]}
        />
        <TextField
          fullWidth
          value={address.city}
          className="mt-8  bg-neutral-2 flex-grow"
          label="City"
          variant="outlined"
          onChange={(e) => updateAddress("city", e.target.value)}
          helperText={errorConfig["city"]}
        />
        <TextField
          fullWidth
          className="mt-8  bg-neutral-2 "
          label="State"
          variant="outlined"
          value={address.state}
          onChange={(e) => updateAddress("state", e.target.value)}
          helperText={errorConfig["state"]}
        />
      </div>
      <div className="p-16 mt-16">
        <div className="d-flex gap-8 justify-content-end">
          <Button
            className="flex-half"
            color="primary"
            variant="contained"
            onClick={handleAddTransport}
          >
            Add Transport
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddTransport;
