import { configureStore } from '@reduxjs/toolkit';
import userSlice from './redux/slices/userSlice';
import reviewSlice from './redux/slices/reviewSlice';
import alertSlice from './redux/slices/alertSlice';
import entitySlice from './redux/slices/entitySlice';

const store = configureStore({
	reducer: {
		user: userSlice,
		review: reviewSlice,
		alert: alertSlice,
		entity: entitySlice
	},
});

export default store;
