import React from 'react';
import logo from "../../../assets/logo.png";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

const Footer = () => {
  return (
    <div className='flex-col align-items-center p-96 truck-bg neutral-1 fw-800'>
      <div className='d-flex gap-96 flex-wrap'>
        <div className='flex-col gap-8 fs-18 '>
          <img className='white-logo' src={logo} alt="Logo" style={{ width: '60px' }}/>
        </div>
        <div className='flex-col gap-16 txt-align-left fs-18 mt-8'>
          <div className="pointer">About</div>
          <div className="pointer">Team</div>
        </div>
        <div className='flex-col gap-16 txt-align-left fs-18 mt-8'>
          <div className="pointer">Privacy Policy</div>
          <div className="pointer">Terms of Service</div>
        </div>
        <div className='flex-col gap-16 txt-align-left fs-18 mt-8'>
          <div className="pointer">1800-889-3021 </div>
          <div className="pointer">info@checkmytransport.com</div>
        </div>
      </div>
      <div>
        <div className='d-flex gap-32 mt-48'>
          <InstagramIcon />
          <FacebookIcon />
          <TwitterIcon />
          <YouTubeIcon />
        </div>
      </div>
    </div>
  );
}

export default Footer;
