export const isEmptyString = (data: string | number | undefined | null) => {
  if (data == null || data === undefined) {
    return true;
  }
  return String(data).length === 0;
};

export const isEmpty = (data: unknown) => {
  if (data == null || data === undefined) {
    return true;
  }
  return false;
};

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
export function convertUTCToLocal(
  utcDateStr: string,
  formatOptions: Intl.DateTimeFormatOptions = {}
): string {
  const utcDate = new Date(utcDateStr);
  const formatter = new Intl.DateTimeFormat("default", formatOptions);
  const localDateStr = formatter.format(utcDate);
  return localDateStr;
}

export const capitalizeFirstLetter = (name: string) => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};
