import Footer from '../Landing/Footer';
import Header from '../common/Header';
import { ExistingReview } from './ExistingReview';
import ReviewSection from './ReviewSection';
import { Transport } from './Transport';
import { Button, Snackbar } from '@mui/material';
import PublishIcon from '@mui/icons-material/Publish';
import Transportdetails from './Transport/Transportdetails';
import { useReview, setReview, setErrorState } from '../../redux/slices/reviewSlice';
import { axiosInstance } from '../../api';
import { useDispatch } from 'react-redux';
import { isEmpty, isEmptyString } from '../util/utils';
import { setMessage } from '../../redux/slices/alertSlice';

const Review = () => {
    const review = useReview();
    const dispatch = useDispatch();
    const validateReviewDetails = () => {
        const newErrorState: { [key: string]: string } = {};
        if (isEmpty(review?.transport)) {
            newErrorState['transport'] = "Transport is required";
        }
        if (isEmptyString(review?.review.comment)) {
            newErrorState['comment'] = "Comment is required";
        }
        return newErrorState;
    };

    const submitReview = async () => {
        const newErrorState = validateReviewDetails();
        dispatch(setErrorState(newErrorState));
        if (Object.keys(newErrorState).length) {
            return;
        }
        try {
            const data = {
                transport: review.transport?.id,
                ...review.review
            }
            await axiosInstance().post('review/', data);
            dispatch(setMessage("Review Submitted. Our Admin will reviwe shorlty."))
            dispatch(setReview({ comment:"", fraud_amount: 0, transaction_amount: 0 }));
        } catch (e) {
            dispatch(setMessage("Something went wrong."))
        }
    };
    return (
        <div>
            <Header />
            <div className='d-flex gap-8 fs-24 fw-600 p-32'>
                <div className='flex-grow mt-96'>
                    Help Us Stop Transport Fraud
                </div>
            </div>
            <Transport />
            {review?.transport ? <Transportdetails /> : null}
            <div className='d-flex'>
                <div className='flex-grow p-32 bg-neutral-3'>
                    <ReviewSection className="flex-grow" />
                    <Button onClick={submitReview} className='mt-16' style={{ width: "250px" }} color="success" variant="contained" startIcon={<PublishIcon />}>
                        Submit
                    </Button>
                </div>
                {review?.transport ? <ExistingReview className='bordered' /> : null }
            </div>
            <Footer />            
        </div>
    );
};

export default Review;
