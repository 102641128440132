import { User } from "../../types";
import { LoginUser, UserLoginResponse } from "./types";
import { axiosInstance } from "../../../api";

export const signUpApi = (data: User) => {
    return axiosInstance(false).post<UserLoginResponse>("/directory/signup/", data);
}
export const loginApi = (data: LoginUser) => {
    return axiosInstance(false).post<UserLoginResponse>("/directory/login/", data);
}
