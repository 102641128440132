import { useDispatch } from "react-redux";
import {
  EntitySlice,
  setAddEntity,
  useEntity,
} from "../../../redux/slices/entitySlice";
import { UpdateEntityFn } from "../../common/AddTransport/types";

export const useStateValues = () => {
  const { transport, load, address, review, sales_lead, call, trip } = useEntity();
  const dispatch = useDispatch();
  const updateShow: UpdateEntityFn<EntitySlice> = (key, value) => {
    dispatch(setAddEntity({ key, value }));
  };
  return {
    transport,
    load,
    address,
    review,
    sales_lead,
    call,
    trip,
    updateShow,
  };
};
