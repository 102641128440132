import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useStateValues } from "./useStateValue";
import CustomAutoComplete from "../../common/CustomAutoComplete";
import { Address, User } from "../../types";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { capitalizeFirstLetter, convertUTCToLocal } from "../../util/utils";
import UserAvatar from "../../common/UserAvatar";
import { CustomAutoCompleteSelected } from "../../common/CustomAutoComplete/types";
import { TablePagination, TextField } from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { useDispatch } from "react-redux";
import { setAddEntity } from "../../../redux/slices/entitySlice";
import { filterCityApi, filterStateApi } from "../AddAddress/api";
import "./index.scss";
import { filterUserApi } from "../User/api";
const SalesDashboard = () => {
  const dispatch = useDispatch();
  const {
    sales_leads,
    updateFilter,
    page,
    setPage,
    page_size,
    setPageSize,
    count,
    updateSalesLead,
    order_by,
    updateSortKey,
  } = useStateValues();

  const columns = [
    { name: "Id", sort_key: "id", width: "50px" },
    { name: "Company Name", sort_key: "company_name", width: "300px" },
    { name: "Created", sort_key: "created" },
    { name: "Added By", sort_key: "added_by.first_name" },
    { name: "Address", sort_key: "address.city", width: "200px" },
    { name: "Phone", sort_key: "phone", width: "150px" },
    { name: "Rating", sort_key: "rating" },
    { name: "Comment", sort_key: "comment", width: "300px" }, 
    { name: "Status", sort_key: "status", width: "200px"  },           
    { name: "Contact Date", sort_key: "contact_date" },
    { name: "Contact By", sort_key: "contact_by.first_name" },
  ];
  return (
    <div className="p-8">
      <div className="d-flex gap-8 flex-wrap">
        <CustomAutoComplete
          className=""
          width={"200px"}
          onSelect={(user: CustomAutoCompleteSelected<User>) => {
            if (user) {
              updateFilter("added_by", (user as User).id);
            } else {
              updateFilter("added_by", null);
            }
          }}
          getOptionLabel={(user: User) => {
            return capitalizeFirstLetter(user.first_name);
          }}
          placeholder="Added By"
          renderOptionFn={(user: User) => <UserAvatar user={user} />}
          dataSource={(text: string) => filterUserApi({ text })}
        />
        <CustomAutoComplete
          className=""
          width={"200px"}
          onSelect={(user: CustomAutoCompleteSelected<User>) => {
            if (user) {
              updateFilter("contact_by", (user as User).id);
            } else {
              updateFilter("contact_by", null);
            }
          }}
          getOptionLabel={(user: User) => {
            return capitalizeFirstLetter(user.first_name);
          }}
          placeholder="Contact By"
          renderOptionFn={(user: User) => <UserAvatar user={user} />}
          dataSource={(text: string) => filterUserApi({ text })}
        />
        <CustomAutoComplete
          className=""
          width={"200px"}
          onSelect={(address: CustomAutoCompleteSelected<Address>) => {
            if (address) {
              updateFilter("city", (address as Address).city);
            } else {
              updateFilter("city", null);
            }
          }}
          getOptionLabel={(address: Address) => address.city}
          placeholder="City"
          renderOptionFn={(address: Address) => <div>{address.city}</div>}
          dataSource={(text: string) => filterCityApi({ city: text })}
        />
        <CustomAutoComplete
          className=""
          width={"200px"}
          onSelect={(address: CustomAutoCompleteSelected<Address>) => {
            if (address) {
              updateFilter("state", (address as Address).state);
            } else {
              updateFilter("state", null);
            }
          }}
          getOptionLabel={(address: Address) => address.state}
          placeholder="State"
          renderOptionFn={(address: Address) => <div>{address.state}</div>}
          dataSource={(text: string) => filterStateApi({ state: text })}
        />
        <TextField
          size="small"
          className=" bg-neutral-2 "
          placeholder="Rating"
          variant="outlined"
          type="number"
          onChange={(e) => updateFilter("rating", Number(e.target.value))}
        />
        <TextField
          size="small"
          className=" bg-neutral-2 "
          placeholder="Pincode"
          variant="outlined"
          type="number"
          onChange={(e) => updateFilter("pincode", Number(e.target.value))}
        />
        <TextField
          size="small"
          className=" bg-neutral-2 "
          placeholder="Status"
          variant="outlined"
          onChange={(e) => updateFilter("status", e.target.value)}
        />
      </div>
      <div className="mt-8">
        <TableContainer sx={{ backgroundColor: "white" }} component={Paper}>
          <div className="d-flex gap-16 flex-wrap align-items-center px-16">
            <PlaylistAddIcon
              onClick={() =>
                dispatch(setAddEntity({ key: "sales_lead", value: true }))
              }
              className="orange-3 pointer"
            />
            <TablePagination
              rowsPerPageOptions={sales_leads.length ? [10, 20, 50] : []}
              component="div"
              count={count}
              rowsPerPage={page_size}
              page={page}
              onPageChange={(_, page) => setPage(page)}
              onRowsPerPageChange={(e) =>
                setPageSize(parseInt(e.target.value, 10))
              }
            />
          </div>
          <Table size="small" className="transport-table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.name}
                    align="left"
                    width={column.width}
                  >
                    <div
                      className="d-flex gap-8 align-items-center pointer"
                      onClick={(e) => {
                        updateSortKey(column.sort_key);
                      }}
                    >
                      <span>{column.name}</span>
                      {column.sort_key.length &&
                      order_by.includes(column.sort_key) ? (
                        <span>
                          <SwapVertIcon />
                        </span>
                      ) : null}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sales_leads.map((lead, index) => (
                <React.Fragment>
                  <TableRow
                    key={lead.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{lead.id}</TableCell>
                    <TableCell align="left">
                      <TextField
                        fullWidth
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="Company Name"
                        variant="outlined"
                        type="text"
                        defaultValue={lead.company_name}
                        onChange={(e) =>
                          updateSalesLead({
                            index,
                            key: "company_name",
                            value: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      {convertUTCToLocal(lead.created!)}
                    </TableCell>
                    <TableCell align="left">
                      <UserAvatar user={lead.added_by} />
                    </TableCell>
                    <TableCell align="left">
                      <TableCell align="left">
                        <span>{(lead.address as Address)?.city}, </span>
                        <span>{(lead.address as Address)?.state}, </span>
                        <span>{(lead.address as Address)?.pincode}</span>
                      </TableCell>
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        fullWidth
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="Phone"
                        variant="outlined"
                        type="text"
                        defaultValue={lead.phone}
                        onChange={(e) =>
                          updateSalesLead({
                            index,
                            key: "phone",
                            value: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="Rating"
                        variant="outlined"
                        type="number"
                        defaultValue={lead.rating}
                        onChange={(e) =>
                          updateSalesLead({
                            index,
                            key: "rating",
                            value: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="Comment"
                        variant="outlined"
                        defaultValue={lead.comment}
                        onChange={(e) =>
                          updateSalesLead({
                            index,
                            key: "comment",
                            value: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="Status"
                        variant="outlined"
                        defaultValue={lead.status}
                        onChange={(e) =>
                          updateSalesLead({
                            index,
                            key: "status",
                            value: e.target.value,
                          })
                        }
                      />
                    </TableCell>                   
                    <TableCell align="left">
                      {lead.contact_date
                        ? convertUTCToLocal(lead.contact_date)
                        : "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      <UserAvatar user={lead.contact_by} />
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default SalesDashboard;
