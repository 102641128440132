import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useStateValues } from "./useStateValues";
import { axiosInstance } from "../../../api";
import { useDispatch } from "react-redux";
import { Transport, TransportReview } from "../../types";
import { setMessage } from "../../../redux/slices/alertSlice";
import CustomAutoComplete from "../../common/CustomAutoComplete";
import { capitalizeFirstLetter } from "../../util/utils";
import UserAvatar from "../../common/UserAvatar";
import { TextField } from "@mui/material";
import { setAddEntity } from "../../../redux/slices/entitySlice";
import { searchTranportApi } from "../TransportDashboard/api";
import { Add } from "@mui/icons-material";
import { CustomAutoCompleteSelected } from "../../common/CustomAutoComplete/types";

export const AddReview = ({ onClose }: { onClose?: () => void }) => {
  const dispatch = useDispatch();

  const { review, updateReview, errorConfig, validate } = useStateValues();

  const handleAddReview = async () => {
    if (validate()) {
      console.log("not validate here", errorConfig);
      return;
    }
    const data = {
      ...review,
    };
    try {
      await axiosInstance().post<TransportReview>("review/", data);
      dispatch(setMessage("Review Added"));
      onClose?.();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <div className="bg-neutral-3 d-flex p-16 align-items-center">
        <div className="fs-18 flex-grow">Add Review</div>
        <div className="pointer" onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      <div className="p-16 overflow-y-scroll" style={{ height: "70vh" }}>
        <div className="d-flex gap-8">
          <Button
            className="d-flex bg-orange-4 neutral-1"
            onClick={() =>
              dispatch(setAddEntity({ key: "transport", value: true }))
            }
          >
            <Add />
            <span>Transport</span>
          </Button>
        </div>
        <CustomAutoComplete
          className="mt-8"
          width={"200px"}
          onSelect={(transport: CustomAutoCompleteSelected<Transport>) => {
            if (transport) {
              updateReview("transport_id", (transport as Transport).id as number);
            }
          }}
          getOptionLabel={(transport: Transport) => {
            return capitalizeFirstLetter(transport.name);
          }}
          placeholder="Transport"
          renderOptionFn={(transport: Transport) => (
            <UserAvatar user={{ first_name: transport.name }} />
          )}
          dataSource={(text: string) => searchTranportApi({ name: text })}
          errorMessage={errorConfig["transport"]}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Comment"
          variant="outlined"
          onChange={(e) => updateReview("comment", e.target.value)}
          helperText={errorConfig['comment']}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Rating"
          type="number"
          variant="outlined"
          onChange={(e) => updateReview("rating", Number(e.target.value))}
        />
      </div>
      <div className="p-16 mt-16">
        <div className="d-flex gap-8 justify-content-end">
          <Button
            className="flex-half"
            color="primary"
            variant="contained"
            onClick={handleAddReview}
          >
            Add Review
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddReview;
