import { useCallback, useState } from "react";
import { Load, Trip } from "../../types";
import { UpdateEntityFn } from "./types";
import { isEmpty } from "../../util/utils";

export const useStateValues = () => {
  const [errorConfig, setErrorConfig] = useState<{ [key: string]: string }>({});
  const [trip, setTrip] = useState<Partial<Load>>({
    status: 'SCHEDULED',    
  });
  const validate = useCallback(() => {
    const newErrorConfig: { [key: string]: string } = {};
    if (isEmpty(trip.transport)) {
      newErrorConfig["transport"] = "Empty Transport";
    }
    if (isEmpty(trip.origin)) {
      newErrorConfig["origin"] = "Empty Origin";
    }
    if (isEmpty(trip.destination)) {
      newErrorConfig["destination"] = "Empty Destination";
    }

    setErrorConfig(newErrorConfig);
    return Object.keys(newErrorConfig).length;
  }, [trip]);

  const updateTrip = useCallback<UpdateEntityFn<Partial<Trip>>>(
    (key, value) => {
      const newTrip = { ...trip };
      newTrip[key] = value;
      setTrip(newTrip);
    },
    [trip]
  );

  return {
    trip,
    updateTrip,
    errorConfig,
    validate,
  };
};
