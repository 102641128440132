import React, { useState } from 'react'
import { TextField } from '@mui/material';
import { LOGIN_VIEW_TYPE } from './types';
import { SocialLogin } from './SocialLogin';
import { User } from '../../types';
import { UpdateEntityFn } from '../AddTransport/types';
import { isEmptyString } from '../../util/utils';
import { signUpApi } from './api';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../redux/slices/userSlice';
export const SignUpPart = ({ setViewType, onClose }: { setViewType: (type: LOGIN_VIEW_TYPE) => void, onClose?: () => void }) => {
    const dispatch = useDispatch();
    const [errorState, setErrorState] = useState<{ [key: string]: string }>({});
    const [data, setData] = useState<User>({
        id: null,
        email: '',
        phone: '',
        first_name: '',
        last_name: '',
        password: ''
    });

    const updateField: UpdateEntityFn<User> = (key, value) => {
        const newData = { ...data };
        newData[key] = value;
        setData(newData);
    }

    const signUp = () => {
        const newErrorState: { [key: string]: string } = {};
        if (isEmptyString(data.email)) {
            newErrorState['email'] = "Email Required";
        }        
        if (isEmptyString(data.first_name)) {
            newErrorState['first_name'] = "First Name Required";
        }
        if (isEmptyString(data.last_name)) {
            newErrorState['last_name'] = "Last Name Required";
        }
        if (isEmptyString(data.phone)) {
            newErrorState['phone'] = "Phone Required";
        }
        if (isEmptyString(data.password)) {
            newErrorState['password'] = "Password Required";
        }
        setErrorState(newErrorState);
        if (Object.keys(newErrorState).length) {
            return;
        }
        signUpApi(data).then(response => {
            console.log("Here", response);
            const user_data = response.data;
            if(user_data.token) {
                dispatch(setUser(user_data))
                onClose?.();
            }
        }).catch((error) => {
            console.log('error', error);
        });
    }
    return (
        <div className='flex-col p-24'>
            <div className='fs-24 txt-align-center neutral-8 '>Login </div>
            <div className='d-flex gap-16 mt-16'>
                <TextField helperText={errorState['first_name']} className=' bg-neutral-2 flex-grow' onChange={(e) => updateField('first_name', e.target.value)}
                    label="First Name" type='text' variant="outlined" />
                <TextField helperText={errorState['last_name']} className=' bg-neutral-2 flex-grow ' onChange={(e) => updateField('last_name', e.target.value)}
                    label="Last Name" type='text' variant="outlined" />
            </div>
            <div className='d-flex gap-16 mt-16'>
                <TextField helperText={errorState['email']} className=' bg-neutral-2 flex-grow ' onChange={(e) => updateField('email', e.target.value)}
                    label="E-mail" type='email' variant="outlined" />
                <TextField helperText={errorState['phone']} className=' bg-neutral-2 flex-grow ' onChange={(e) => updateField('phone', e.target.value)}
                    label="Phone No" type='phone' variant="outlined" />
            </div>
            <div className='d-flex  align-items-center mt-16'>
                <TextField helperText={errorState['password']} className=' bg-neutral-2 flex-grow ' onChange={(e) => updateField('password', e.target.value)}
                    label="Password" variant="outlined" type='password' />
            </div>

            <div className='d-flex p-16 bg-orange-4 justify-content-center mt-16 pointer' onClick={signUp}>
                <span className='neutral-1 '>SIGN UP </span>
            </div>
            <div className='d-flex neutral-8 p-16 gap-8 justify-content-center'>
                <div> Already a member?<span className='blue-4 pointer' onClick={() => setViewType('LOGIN')}> SIGN IN</span></div>
            </div>
            <SocialLogin />
        </div>
    )
}
