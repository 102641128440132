export const STEP = {
  INFO: 0,
  ADDRESS: 1,
  CONTACT: 2,
  REVIEW: 3,
};

export type UpdateEntityFn<T> = <K extends keyof T, V>(
  key: K,
  value: V extends T[K] ? V : never
) => void;
export type UpdateEntityDebounceIndexFn<T> = <K extends keyof T, V>({
  index,
  key,
  value,
}: {
  index: number;
  key: K;
  value: V extends T[K] ? V : never;
}) => void;

export type UpdateEntityDebounceFn<T> = <K extends keyof T, V>({  
  key,
  value,
}: {  
  key: K;
  value: V extends T[K] ? V : never;
}) => void;
