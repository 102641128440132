import { TextField } from "@mui/material";
import React from "react";
import { Transport } from "../../../types";
import { UpdateEntityFn } from "../types";

export const Info = ({
  transport,
  updateTransport,
  errorConfig,
}: {
  transport: Transport;
  updateTransport: UpdateEntityFn<Transport>;
  errorConfig: { [key: string]: string };
}) => {
  return (
    <div>
      <div className="fs-16 mt-32  txt-align-center neutral-8">Basic Info </div>
      <div className="d-flex  align-items-center mt-16">
        <TextField
          value={transport.name}
          className=" bg-neutral-2 flex-grow "
          label="Transporter Name"
          variant="outlined"
          onChange={(e) => updateTransport("name", e.target.value)}
          helperText={errorConfig['transport_name']}
        />
      </div>
      <div className="d-flex gap-16 mt-24">
        <div className="flex-grow gap-16 align-items-center">
          <TextField
            value={transport.phone}
            className=" bg-neutral-2 "
            label="Phone Number"
            variant="outlined"
            type="number"
            onChange={(e) => updateTransport("phone", e.target.value)}
            helperText={errorConfig['transport_phone']}
          />
        </div>
        <TextField
          value={transport.email}
          className=" bg-neutral-2 "
          label="E-mail (Optional)"
          variant="outlined"
          type="email"
          onChange={(e) => updateTransport("email", e.target.value)}
        />
      </div>
    </div>
  );
};
