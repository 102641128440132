import { Data } from "../../types";

export interface TripStatus extends Data {}
export interface CallStatus extends Data {}
export const TRIP_STATUS: TripStatus[] = [
  {
    id: "SCHEDULED",
  },
  {
    id: "TRANSIT",
  },
  {
    id: "COMPLETE",
  },
  {
    id: "CANCELED",
  },
];


export const CALL_STATUS: CallStatus[] = [
  {
    id: "NOT_PICKED",
  },
  {
    id: "NOT_OPERATIONAL",
  },
  {
    id: "LOAD_POSSIBLE",
  },
  {
    id: "CALL_BACK",
  },
];
