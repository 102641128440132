import { useCallback, useState } from "react";
import { SalesLead, TransportReview } from "../../types";
import { UpdateEntityFn } from "./types";
import { isEmpty } from "../../util/utils";

export const useStateValues = () => {
  const [errorConfig, setErrorConfig] = useState<{ [key: string]: string }>({});
  const [sales_lead, setSalesLead] = useState<Partial<SalesLead>>({
    status: 'INIT'  
  });
  const validate = useCallback(() => {
    const newErrorConfig: { [key: string]: string } = {};
    if (isEmpty(sales_lead.company_name)) {
      newErrorConfig["company_name"] = "Empty Company Nmae";
    }
    if (isEmpty(sales_lead.address)) {
      newErrorConfig["address"] = "Empty Address";
    }
    if (isEmpty(sales_lead.phone)) {
      newErrorConfig["phone"] = "Empty Phone";
    }
    setErrorConfig(newErrorConfig);
    return Object.keys(newErrorConfig).length;
  }, [sales_lead]);

  const updateSalesLead = useCallback<UpdateEntityFn<Partial<SalesLead>>>(
    (key, value) => {
      const new_review = { ...sales_lead };
      new_review[key] = value;
      setSalesLead(new_review);
    },
    [sales_lead]
  );

  return {
    sales_lead,
    updateSalesLead,
    errorConfig,
    validate,
  };
};
