import React, { useState } from 'react'
import { CustomAvatar } from '../../../common/CustomAvatar'
import { UserReview } from '../../../types'
import { convertUTCToLocal } from '../../../util/utils'

export const UserReviewRow = ({ userReview, row }: { userReview: UserReview, row: number }) => {
  const reviewBgClass = ['bg-neutral-2', 'bg-neutral-3'];
  const reviewLength = userReview.comment.length;
  const maxLength = 50;
  const [view, setView] = useState(false);
  return (
    <div className={`d-flex p-8 bg-neutral-2 ${reviewBgClass[row % 2]}`}>
      <div style={{ flex: "0 0 70px" }} className='d-flex pt-16 align-items-start justify-content-center'>
        <CustomAvatar user={userReview.user} />
      </div>
      <div className='flex-grow'>
        <div className="p-8 fs-14">
          <span>{userReview.user.first_name} {userReview.user.last_name} posted {userReview.comment.substring(0, view ? reviewLength : maxLength)}</span>
          {reviewLength > maxLength && !view && <span>...</span>}
          {reviewLength > maxLength && <div onClick={() => setView(!view)} className='fs-12 green-3 mt-8 pointer'>{view ? 'View Less' : 'View More'}</div>}
        </div>
        <div className="p-8 fs-12">Reviwed on {convertUTCToLocal(userReview.created)}</div>
      </div>
    </div>
  )
}
