import { axiosInstance } from "../../../api";
import {
  BlackList,
  NumStrNull,
  PaginatedResponse,
  Transport,
  TransportReview,
} from "../../types";

export const listReviewApi = ({
  transport_id,
  rating,
  page = 1,
  page_size = 5,
  order_by,
}: {
  transport_id?: number;
  rating?: number;
  page?: number;
  page_size?: number;
  order_by?: string;
}) => {
  const urlParams = new URLSearchParams();
  if (rating) {
    urlParams.set("rating", String(rating));
  }
  if (transport_id) {
    urlParams.set("transport_id", String(transport_id));
  }
  if (order_by) {
    urlParams.set("order_by", String(order_by));
  }
  urlParams.set("page", String(page));
  urlParams.set("limit", String(page_size));
  return axiosInstance()
    .get<PaginatedResponse<TransportReview>>(
      `/backoffice/review/list-detail?page=1&${urlParams.toString()}`
    )
    .then((response) => response.data);
};

export const updateReviewApi = (
  review_id: number,
  data: Partial<TransportReview>
) => {
  return axiosInstance()
    .patch<Transport>(`/backoffice/review/${review_id}/`, data)
    .then((response) => response.data);
};

export const updateBlacklistApi = (
  blacklist_id: number,
  data: Partial<TransportReview>
) => {
  return axiosInstance()
    .patch<BlackList>(`/backoffice/blacklist/${blacklist_id}/`, data)
    .then((response) => response.data);
};

export const createBlacklistApi = (data: Partial<TransportReview>) => {
  return axiosInstance()
    .post<BlackList>(`/backoffice/blacklist/`, data)
    .then((response) => response.data);
};
