import { useCallback, useState } from "react";
import { TransportReview } from "../../types";
import { UpdateEntityFn } from "./types";
import { isEmpty } from "../../util/utils";

export const useStateValues = () => {
  const [errorConfig, setErrorConfig] = useState<{ [key: string]: string }>({});
  const [review, setReview] = useState<Partial<TransportReview>>({});
  const validate = useCallback(() => {
    const newErrorConfig: { [key: string]: string } = {};
    if (isEmpty(review.transport_id)) {
      newErrorConfig["transport_id"] = "Empty transport name";
    }
    if (isEmpty(review.comment)) {
      newErrorConfig["comment"] = "Empty Review";
    }

    setErrorConfig(newErrorConfig);
    return Object.keys(newErrorConfig).length;
  }, [review]);

  const updateReview = useCallback<UpdateEntityFn<Partial<TransportReview>>>(
    (key, value) => {
      const new_review = { ...review };
      new_review[key] = value;
      setReview(new_review);
    },
    [review]
  );

  return {
    review,
    updateReview,
    errorConfig,
    validate,
  };
};
