import { Route, Routes, useLocation } from "react-router-dom";
import { PROTECTED_ROUTES, PUBLIC_ROUTES } from "./routes";
import { Suspense, useEffect } from "react";
import CustomProgress from "./components/common/CustomProgress";

const RouteComponent = () => {
    const location = useLocation()
    useEffect(() => {
        const path = location.pathname;    
    }, [location.pathname]);
    return (
        <Routes>
            {PUBLIC_ROUTES.map((route) => {
                const Component = route.component;
                return <Route key={route.path} path={route.path}
                    element={
                        <Suspense fallback={<CustomProgress />}>
                            <Component />
                        </Suspense>
                    }></Route>;
            })}
            {PROTECTED_ROUTES.map((route) => {
                const Component = route.component;
                return <Route key={route.path} path={route.path}
                    element={
                        <Suspense fallback={<CustomProgress />}>
                            <Component />
                        </Suspense>
                    }></Route>;
            })}
        </Routes>
    );
}
export default RouteComponent;