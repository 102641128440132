import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../types';
import { User } from '../../components/types';

export interface UserSlice {
	user: User | null,
	token: string | null,
	showLogin: boolean
}
const baseUser = localStorage.getItem('user');
const baseToken = localStorage.getItem('token');
const initialState: UserSlice = {
	user: baseUser ? JSON.parse(baseUser) : null,
	token: baseToken,
	showLogin: false
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
			state.token = action.payload.token;
			localStorage.setItem('user', JSON.stringify(action.payload));
			localStorage.setItem('token', action.payload.token);
		},
		showLogin: (state, action) => {
			state.showLogin = action.payload;
		},
		logout: (state) => {
			state.user = null;
			state.token = null;
			localStorage.removeItem('user');
			localStorage.removeItem('token');
		},
	},
});


export const { setUser, logout, showLogin } = userSlice.actions;

export default userSlice.reducer;

export const useUser: () => UserSlice = () => useSelector((state: ReduxStore) => state.user);	