import { Avatar } from "@mui/material";
import { stringToColor } from "../../util/utils";

export const CustomAvatar = ({
  user,
  className,
}: {
  user?: { first_name: string; last_name?: string };
  className?: string;
}) => {
  return (
    <Avatar
      className={className}
      sx={{
        width: 32,
        height: 32,
        bgcolor: stringToColor(user?.first_name.charAt(0).toUpperCase() || ""),
      }}
    >
      <span className="text neutral-1 medium">
        {user?.first_name.charAt(0).toUpperCase()}
      </span>
    </Avatar>
  );
};
