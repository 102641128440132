import React from 'react'
import { Address, Transport, User } from '../../../types'
export const Review = ({
    user,
    transport,
    address
}: {
    user: Partial<User>,
    transport: Transport,
    address: Address
}) => {
    return (
        <div>
            <div className="flex-col border p-16 mt-14 rounded-16 bg-neutral-3 ">
                <div className='fs-16  txt-align-center neutral-8'> Basic Info</div>
                <div className='px-8'>
                    <div className='mt-8'>
                        <div className="fs-12 neutral-9">Transport Name</div>
                        <div className='fs-16 neutral-10'>{transport.name}</div>
                    </div>
                    <div className='mt-8'>
                        <div className="fs-12 neutral-9">Phone NO</div>
                        <div className='fs-16 neutral-10'>{transport.phone}</div>
                    </div>
                    <div className='mt-8'>
                        <div className="fs-12 neutral-9">E-mail</div>
                        <div className='fs-16 neutral-10'>{transport.email}</div>
                    </div>
                </div>
            </div>
            <div className="flex-col border p-16 mt-16 rounded-16 bg-neutral-3 ">
                <div className='fs-16  txt-align-center neutral-8'> Transporter Address</div>
                <div className=' px-8'>
                    <div className='mt-8'>
                        <div className='fs-12 neutral-9' >Address 1</div>
                        <div>{address.address_1}</div>
                    </div>
                    <div className='mt-8'>
                        <div className='fs-12 neutral-9' >Address 2</div>
                        <div>{address.address_2}</div>
                    </div>
                    <div className='d-flex gap-16'>
                        <div className='mt-8'>
                            <div className='fs-12 neutral-9' >City</div>
                            <div>{address.city}</div>
                        </div>
                        <div className='mt-8'>
                            <div className='fs-12 neutral-9' >State</div>
                            <div>{address.state}</div>
                        </div>
                        <div className='mt-8'>
                            <div className='fs-12 neutral-9' >Pin</div>
                            <div>{address.pincode}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-col border p-16 mt-16 rounded-16 bg-neutral-3 ">
                <div className='fs-16  txt-align-center neutral-8'> User Info</div>
                <div className='d-flex gap-16 px-8'>
                    <div className='mt-8 flex-half'>
                        <div className='fs-12 neutral-9 '>First Name</div>
                        <div>{user.first_name}</div>
                    </div>
                    <div className=' mt-8 flex-half'>
                        <div className='fs-12 neutral-9'>Last Name</div>
                        <div>{user.last_name}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
