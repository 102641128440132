import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../../api";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
const AdminInfo = () => {
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const [dashboard_data, setDashboardData] = useState({
    pending_amount: 0,
    amount_collected: 0,
    total_trip: 0,
    live_pending_amount: 0,
    live_amount_collected: 0,
    live_trip: 0,
  });
  const getDashboardData = () => {
    axiosInstance()
      .get(`backoffice/dashboard/data/`)
      .then((response) => setDashboardData(response.data || {}));
  };
  useEffect(() => {
    getDashboardData();
  }, []);
  return (
    <div className="p-16 flex gap-32 flex-grow justify-content-center">
      <div className="flex gap-2 justify-content-center">
        <div>
          <PendingActionsIcon />
        </div>
        <div>{formatter.format(dashboard_data.pending_amount)}</div>
      </div>
      <div className="flex gap-2 justify-content-center">
        <div>
          <AccountBalanceWalletIcon />
        </div>
        <div>{formatter.format(dashboard_data.amount_collected)}</div>
      </div>
      <div className="flex gap-2 justify-content-center">
        <div>
          <FormatAlignJustifyIcon />
        </div>
        <div>{dashboard_data.total_trip}</div>
      </div>
      <div className="flex gap-2 justify-content-center">
        <div>
          <WorkHistoryIcon />
        </div>
        <div>{formatter.format(dashboard_data.live_pending_amount)}</div>
      </div>
      <div className="flex gap-2 justify-content-center">
        <div>
          <AccountBalanceIcon />
        </div>
        <div>{formatter.format(dashboard_data.live_amount_collected)}</div>
      </div>
      <div className="flex gap-2 justify-content-center">
        <div>
          <ModeOfTravelIcon />
        </div>
        <div>{dashboard_data.live_trip}</div>
      </div>
    </div>
  );
};

export default AdminInfo;
