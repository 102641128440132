import { Button, Stack, Step, StepLabel, Stepper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useStateValues } from './useStateValues';
import { Info } from './Info';
import { AddressInfo } from './AddressInfo';
import { Contact } from './Contact';
import { Review } from './Review';
import { STEP } from './types';
import { axiosInstance } from '../../../api';
import { useDispatch } from 'react-redux';
import { setTransport } from '../../../redux/slices/reviewSlice';
import { Transport } from '../../types';

export const AddTransport = ({ onClose }: { onClose?: () => void }) => {
    const dispatch = useDispatch();
    const steps = ["Info", "Address", "Contact", "Review"];
    const { step,
        updateStep,
        user,
        updateUser,
        address,
        updateAddress,
        transport,
        updateTransport,
        errorConfig
    } = useStateValues();

    const handleAddTransport = async () => {
        const data = {
            user,
            address,
            ...transport
        }
        try {
            const response = await axiosInstance().post<Transport>('transport/', data);
            dispatch(setTransport(response.data))
            onClose?.();
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div>
            <div className='bg-neutral-3 d-flex p-16 align-items-center'>
                <div className='fs-18 flex-grow'>Add Transporter</div>
                <div className='pointer' onClick={onClose}>
                    <CloseIcon />
                </div>
            </div>
            <div className='p-16'>
                <Stack sx={{ width: '100%' }} spacing={4}>
                    <Stepper alternativeLabel activeStep={1}>
                        {steps.map((i_step, index) => (
                            <Step completed={step > index} key={i_step} index={index} active={step === index}>
                                <StepLabel>
                                    <div className='fs-14 black-3'>{i_step}</div>
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Stack>
            </div>
            <div className='p-16 overflow-y-scroll' style={{ height: "50vh" }}>
                {
                    {
                        [STEP.INFO]: <Info errorConfig={errorConfig} transport={transport} updateTransport={updateTransport} />,
                        [STEP.ADDRESS]: <AddressInfo errorConfig={errorConfig} address={address} updateAddress={updateAddress} />,
                        [STEP.CONTACT]: <Contact errorConfig={errorConfig} user={user} updateUser={updateUser} />,
                        [STEP.REVIEW]: <Review user={user} transport={transport} address={address} />
                    }[step]
                }
            </div>
            <div className='p-16 mt-16'>
                <div className='d-flex gap-8 justify-content-end'>
                    {step > STEP.INFO ? <Button className='flex-half' color="primary" variant="contained" onClick={() => updateStep(step - 1)}>
                        Back
                    </Button> : null}
                    <Button className='flex-half' color="primary" variant="contained" onClick={step === STEP.REVIEW ? handleAddTransport : () => updateStep(step + 1)}>
                        {step === STEP.REVIEW ? "Add Transport" : "Next"}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AddTransport;