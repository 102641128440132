import * as React from 'react';

import { LOGIN_VIEW_TYPE } from './types';
import { SignUpPart } from './SignUpPart';
import { LoginPart } from './LoginPart';

export default function LoginContainer({ onClose, view }: { onClose: () => void, view: LOGIN_VIEW_TYPE }) {
  const [viewType, setViewType] = React.useState<LOGIN_VIEW_TYPE>(view);

  return (
    viewType === "LOGIN" ? <LoginPart onClose={onClose} setViewType={(type: LOGIN_VIEW_TYPE) => setViewType(type)} /> :
      <SignUpPart onClose={onClose} setViewType={(type: LOGIN_VIEW_TYPE) => setViewType(type)} />
  );
}
