import { useCallback, useState } from "react";
import { Load } from "../../types";
import { UpdateEntityFn } from "./types";
import { isEmpty } from "../../util/utils";

export const useStateValues = () => {
  const [errorConfig, setErrorConfig] = useState<{ [key: string]: string }>({});
  const [load, setLoad] = useState<Partial<Load>>({
    load_source: "ADMIN_PORTAL",
    is_public: false
  });
  const validate = useCallback(() => {
    const newErrorConfig: { [key: string]: string } = {};
    if (isEmpty(load.load_owner)) {
      newErrorConfig["load_owner"] = "Empty transport name";
    }
    if (isEmpty(load.origin)) {
      newErrorConfig["origin"] = "Empty Origin";
    }

    setErrorConfig(newErrorConfig);
    return Object.keys(newErrorConfig).length;
  }, [load.load_owner, load.origin]);

  const updateLoad = useCallback<UpdateEntityFn<Partial<Load>>>(
    (key, value) => {
      const new_load = { ...load };
      new_load[key] = value;
      setLoad(new_load);
    },
    [load]
  );

  return {
    load,
    updateLoad,
    errorConfig,
    validate,
  };
};
