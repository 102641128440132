import * as React from 'react';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';


const CustomRating = ({
    className,
    onChange,
    defaultValue = 5
}: {
    className?: string,
    onChange: (value: number) => void,
    defaultValue?: number
}) => {
    const [value, setValue] = React.useState<number | null>(2);
    return (
        <div className={`d-flex align-items-center ${className}`}>
            <Rating
                name="hover-feedback"
                defaultValue={defaultValue}
                value={value}
                precision={0.5}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    onChange(newValue || 0);
                }}
                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
            />
        </div>
    );
};

export default CustomRating;
