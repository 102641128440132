import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { FraudData, TransactionData } from '../../../common/Login/types';

export default function BasicBars({ fraud, transaction }: { fraud: FraudData[], transaction: TransactionData[] }) {
  // Function to get the ordinal suffix for a day
  function getOrdinalSuffix(day: number): string {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  // Function to format date to '13th July' format
  function formatDateToOrdinal(date: Date): string {
    const day = date.getUTCDate();
    const dayWithSuffix = day + getOrdinalSuffix(day);

    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const monthName = monthNames[date.getUTCMonth()];
    return `${dayWithSuffix} ${monthName}`;
  }

  // Function to get an array of the past 7 days formatted
  function getLast7DaysFormatted(): string[] {
    const dates: string[] = [];
    const today = new Date();

    for (let i = 7; i >= 0; i--) {
      const pastDate = new Date(today);
      pastDate.setDate(today.getDate() - i);
      dates.push(formatDateToOrdinal(pastDate));
    }

    return dates;
  }

  // Generate the past 7 days formatted array
  const past7Days = getLast7DaysFormatted();
  console.log(past7Days);  // Output: ['14th July', '15th July', '16th July', '17th July', '18th July', '19th July', '20th July']

  // Create a mapping of frauddata by date
  const fraudDataMap: { [key: string]: number } = {};
  const transactionDataMap: { [key: string]: number } = {};
  fraud.forEach(entry => {
    const date = new Date(entry.day);
    fraudDataMap[formatDateToOrdinal(date)] = entry.total_amount;
  });

  transaction.forEach(entry => {
    const date = new Date(entry.day);
    transactionDataMap[formatDateToOrdinal(date)] = entry.total_amount;
  });

  // Map the past 7 days to the total_amount
  const fraudData = past7Days.map(date => {
    return fraudDataMap[date]/1000 || 0;
  });

  // Map the past 7 days to the total_amount
  const transactionData = past7Days.map(date => {
    return transactionDataMap[date]/1000 || 0;
  });

  console.log(fraudData);  // Output: [0, 0, 0, 0, 0, 0, 405778853]

  console.log(fraud);
  return (
    <BarChart
      xAxis={[{ scaleType: 'band', data: past7Days }]}
      series={[{ data: fraudData }, { data: transactionData }]}
      height={300}
    />
  );
}
