import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useStateValues } from "./useStateValues";
import { axiosInstance } from "../../../api";
import { useDispatch } from "react-redux";
import { Address, TransportReview } from "../../types";
import { setMessage } from "../../../redux/slices/alertSlice";
import CustomAutoComplete from "../../common/CustomAutoComplete";
import { TextField } from "@mui/material";
import { setAddEntity } from "../../../redux/slices/entitySlice";
import { Add } from "@mui/icons-material";
import { CustomAutoCompleteSelected } from "../../common/CustomAutoComplete/types";
import { filterAddressApi } from "../AddAddress/api";

export const AddSalesLead = ({ onClose }: { onClose?: () => void }) => {
  const dispatch = useDispatch();

  const { sales_lead, updateSalesLead, errorConfig, validate } =
    useStateValues();

  const handleAddSalesLead = async () => {
    if (validate()) {
      console.log("not validate here", errorConfig);
      return;
    }
    const data = {
      ...sales_lead,
    };
    try {
      await axiosInstance().post<TransportReview>("backoffice/sales/", data);
      dispatch(setMessage("Lead Added"));
      onClose?.();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <div className="bg-neutral-3 d-flex p-16 align-items-center">
        <div className="fs-18 flex-grow">Add Sales Lead</div>
        <div className="pointer" onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      <div className="p-16 overflow-y-scroll" style={{ height: "70vh" }}>
        <div className="d-flex gap-8">
          <Button
            className="d-flex bg-orange-4 neutral-1"
            onClick={() =>
              dispatch(setAddEntity({ key: "address", value: true }))
            }
          >
            <Add />
            <span>Address</span>
          </Button>
        </div>
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Company Name"
          variant="outlined"
          onChange={(e) => updateSalesLead("company_name", e.target.value)}
          helperText={errorConfig['company_name']}
        />
        <CustomAutoComplete
          className="mt-8"
          width={"200px"}
          onSelect={(address: CustomAutoCompleteSelected<Address>) => {
            if (address) {
              updateSalesLead("address", (address as Address).id as number);
            }
          }}
          getOptionLabel={(address: Address) => {
            return `${address.city}, ${address.state}, ${address.pincode}`;
          }}
          placeholder="Address"
          renderOptionFn={(address: Address) => (
            <span>
              {`${address.city}, ${address.state}, ${address.pincode}`}
            </span>
          )}
          dataSource={(text: string) => filterAddressApi({ text })}
          errorMessage={errorConfig["address"]}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Rating"
          type="number"
          variant="outlined"
          onChange={(e) => updateSalesLead("rating", Number(e.target.value))}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Phone"
          type="number"
          variant="outlined"
          onChange={(e) => updateSalesLead("phone", e.target.value)}
          helperText={errorConfig["phone"]}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Link"
          variant="outlined"
          onChange={(e) => updateSalesLead("company_link", e.target.value)}
      />        
      </div>
      <div className="p-16 mt-16">
        <div className="d-flex gap-8 justify-content-end">
          <Button
            className="flex-half"
            color="primary"
            variant="contained"
            onClick={handleAddSalesLead}
          >
            Add Lead
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddSalesLead;
