import { axiosInstance } from "../../../api";
import {
  Address,
  PaginatedResponse,
  Transport,
  TransportCall,
} from "../../types";

export const listTransportCallApi = ({
  transport_name,
  status,
  order_by = "created",
  added_by,
  page = 1,
  page_size = 5,
}: {
  transport_name?: string;
  status?: string; 
  added_by?: number;
  order_by?: string;
  page?: number;
  page_size?: number;
}) => {
  const urlParams = new URLSearchParams();
  urlParams.set("order_by", order_by);
  urlParams.set("page", String(page));
  urlParams.set("limit", String(page_size));

  if (transport_name) {
    urlParams.set("transport_name", transport_name);
  }
  if (status) {
    urlParams.set("status", String(status));
  }
  if (added_by) {
    urlParams.set("added_by", String(added_by));
  }  
  return axiosInstance()
    .get<PaginatedResponse<TransportCall>>(
      `/backoffice/transport_call/list-detail/?page=1&${urlParams.toString()}`
    )
    .then((response) => response.data);
};

export const filterLoadCityApi = ({
  city,
  page = 1,
  page_size = 5,
}: {
  city?: string;
  page?: number;
  page_size?: number;
}) => {
  const urlParams = new URLSearchParams();
  if (city) {
    urlParams.set("city", city);
  }
  urlParams.set("page", String(page));
  urlParams.set("limit", String(page_size));
  return axiosInstance()
    .get<PaginatedResponse<Address>>(
      `/backoffice/transport/city/?page=1&${urlParams.toString()}`
    )
    .then((response) => response.data);
};

export const updateTransportCallApi = (
  tranport_id: number,
  data: { [key: string]: unknown }
) => {
  return axiosInstance()
    .patch<Transport>(`/backoffice/transport_call/${tranport_id}/`, data)
    .then((response) => response.data);
};