import * as React from 'react';
import { Button, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CustomModal from '../../common/CustomModal';
import { AddTransport } from '../../common/AddTransport';
import { showLogin, useUser } from '../../../redux/slices/userSlice';
import { useDispatch } from 'react-redux';
import { setTransport, useReview } from '../../../redux/slices/reviewSlice';
import { getTransportByPhone } from './api';

export const Transport = () => {
    const [addTransport, setAddTransport] = React.useState(false);
    const [transporterNumber, setTransporterNumber] = React.useState('');
    const userSlice = useUser();
    const review = useReview();
    const dispatch = useDispatch();
    const [noTrasnportText, setNoTransportText] = React.useState("");

    React.useEffect(() => {
        if (transporterNumber.length === 10) {
            getTransportByPhone(transporterNumber).then(response => {
                const transport = response.data;
                if (transport.length) {
                    dispatch(setTransport(transport[0]));
                } else {
                    setNoTransportText("No transporter found");
                }
            }).catch(() => {
                setNoTransportText("No transporter found");
            });
        } else {
            setNoTransportText("");
        }
    }, [dispatch, transporterNumber]);


    const onAddTransport = () => {
        if (userSlice.token) {
            setAddTransport(true);
        } else {
            dispatch(showLogin(true));
        }
    };

    React.useEffect(() => {
        console.log("Review", review);
    }, [review]);

    return (
        <div className='d-flex mt-18'>
            <div className='d-flex gap-8 p-32 fs-18 fw-600 align-items-center'>
                <TextField
                    label="Transport Phone Number"
                    variant="outlined"
                    placeholder='Search Transport By Phone'
                    value={transporterNumber}
                    onChange={(e) => setTransporterNumber(e.target.value)}
                    helperText={noTrasnportText}
                />
            </div>
            <div className='d-flex p-32'>
                <Button variant="outlined" startIcon={<AddIcon />} onClick={() => onAddTransport()}>
                    New Transport
                </Button>
                {addTransport && (
                    <CustomModal
                        className='bg-neutral-1'
                        width={400}
                        open={addTransport}
                        onClose={() => setAddTransport(false)}
                    >
                        <AddTransport onClose={() => setAddTransport(false)} />
                    </CustomModal>
                )}
            </div>
            <div className='p-32'>
                {review.errorState['transport'] && <p>{review.errorState['transport']}</p>}
            </div>
        </div>
    );
};

export default Transport;
