import { useCallback, useEffect, useState } from "react";
import { Map, SalesLead } from "../../types";
import { UpdateEntityFn } from "../../common/AddTransport/types";
import {
  listTranportApi,
  updateSalesLeadApi
} from "./api";
import { debounce } from "../../util/debounce";

export const useStateValues = () => {
  const [sales_leads, setSalesLeads] = useState<SalesLead[]>([]);
  const [page, setPage] = useState(0);
  const [page_size, setPageSize] = useState(50);
  const [count, setCount] = useState(0);
  const [order_by, setOrderBy] = useState("-created");
  const [filter, setFilter] = useState<Map>({});
  const updateFilter: UpdateEntityFn<Map> = useCallback(
    (key, value) => {
      const newFilters = { ...filter };
      newFilters[key] = value;
      setFilter(newFilters);
    },
    [filter]
  );
  const getSalesLead = useCallback(() => {
    listTranportApi({
      ...filter,
      page: page + 1,
      page_size,
      order_by,
    }).then((data) => {
      setSalesLeads(data.results);
      setCount(data.count);
    });
  }, [filter, order_by, page, page_size]);
  useEffect(() => {
    getSalesLead();
  }, [filter, getSalesLead]);
  const updateSalesLead = ({
    index,
    key,
    value,
  }: {
    index: number;
    key: string;
    value: string;
  }) => {
    const newSalesLeads = [...sales_leads];
    newSalesLeads[index][key] = value;
    setSalesLeads(newSalesLeads);
    updateSalesLeadApi(sales_leads[index].id as number, {
      [key]: value,
    });
  }; 

  const updateSortKey = (sort_key: string) => {
    if (sort_key?.length) {
      if (order_by.includes(sort_key)) {
        if (order_by === sort_key) {
          setOrderBy(`-${sort_key}`);
        } else {
          setOrderBy(sort_key);
        }
      } else {
        setOrderBy(sort_key);
      }
    }
  };
  return {
    sales_leads,
    setSalesLeads,
    filter,
    updateFilter,
    page,
    setPage,
    page_size,
    setPageSize,
    count,
    updateSalesLead: debounce(updateSalesLead),
    order_by,
    updateSortKey,
  };
};
