import { useState, useEffect, useRef, useCallback } from "react";
import { CustomAutoCompleteProps } from "./types";
import { Autocomplete, Box, TextField } from "@mui/material";
import "./index.scss";
import { debounce } from "../../util/debounce";
import { Data } from "../../types";
const CustomAutoComplete = <T extends Data>({
  width,
  height = "40px",
  defaultValue,
  label,
  placeholder,
  onSelect,
  disableClearable,
  forcePopupIcon,
  renderOptionFn,
  dataSource,
  dataList,
  getOptionLabel,
  rootClassName,
  inputClassName,
  multiple = false,
  className,
  size,
  disabled = false,
  fullWidth,
}: CustomAutoCompleteProps<T>) => {
  const initialValueRef = useRef(defaultValue);
  const [options, setOptions] = useState<T[]>(dataList || []);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState<string>();
  const isActiveRef = useRef(false);
  const [selected, setSelected] = useState<T[] | T | null | undefined>(
    defaultValue
  );
  const fetch = useCallback(
    debounce(async (text: string) => {
      try {
        const data = await dataSource?.(text);
        if (isActiveRef.current && data?.results) {
          setOptions(data?.results);
          setLoading(false);
        }
        return data;
      } catch (e) {
        console.log(e);
      }
    }, 200),
    [dataSource, isActiveRef]
  );
  useEffect(() => {
    if (text) {
      if (Array.isArray(selected)) {
        
      } else {
        if (selected) {
          console.log(getOptionLabel?.(selected));
          if (getOptionLabel?.(selected) === text) {
            return;
          }
        }
      }
      setLoading(true);
      isActiveRef.current = true;
      fetch?.(text);
    }

    return () => {
      isActiveRef.current = false;
    };
  }, [fetch, text]);

  return (
    <Autocomplete
      fullWidth={fullWidth}
      className={`custom-auto-complete ${className}`}
      size={size || "small"}
      disabled={disabled}
      defaultValue={initialValueRef.current}
      // value={text}
      sx={{ minWidth: width, minHeight: height }}
      classes={{
        root: rootClassName,
        inputRoot: `custom-auto-complete-input ${inputClassName}`,
      }}
      options={options}
      loading={loading}
      disableClearable={disableClearable}
      getOptionLabel={
        typeof getOptionLabel === "function" ? getOptionLabel : undefined
      }
      forcePopupIcon={forcePopupIcon}
      ListboxProps={{ style: { fontSize: 16 } }}
      ChipProps={{ style: { fontSize: 16 } }}
      filterOptions={(x) => x}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ display: "flex", alignItems: "center" }}
          {...props}
        >
          {renderOptionFn(option)}
        </Box>
      )}
      onInputChange={(_, newInputValue) => {
        setText(newInputValue);
      }}
      onChange={(_, newValue) => {
        onSelect(newValue);
      }}
      multiple={multiple}
      isOptionEqualToValue={(option, value) => option.id == value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{ ...params.InputProps, ...{ style: { fontSize: 16 } } }}
          InputLabelProps={{ style: { fontSize: 16 } }}
          variant="outlined"
          label={label}
          placeholder={placeholder || label}
        />
      )}
    />
  );
};
export default CustomAutoComplete;
