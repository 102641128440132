import { useState } from "react";
import { Address, Transport, User } from "../../types";
import { STEP, UpdateEntityFn } from "./types";
import { isEmptyString } from "../../util/utils";

export const useStateValues = () => {
  const [step, setStep] = useState(0);
  const [errorConfig, setErrorConfig] = useState<{ [key: string]: string }>({});
  const updateStep = (new_step: number) => {
    const newErrorConfig: { [key: string]: string } = {};
    if (new_step === STEP.ADDRESS) {
      if (isEmptyString(transport.name)) {
        newErrorConfig["transport_name"] = "Empty transport name";
      }
      if (isEmptyString(transport.phone)) {
        newErrorConfig["transport_name"] = "Empty transport phone";
      }
    }
    if (new_step === STEP.CONTACT) {
      if (isEmptyString(address.address_1)) {
        newErrorConfig["address_1"] = "Empty address 1";
      }
      if (isEmptyString(address.city)) {
        newErrorConfig["city"] = "Empty city";
      }
      if (isEmptyString(address.state)) {
        newErrorConfig["state"] = "Empty state";
      }
      if (isEmptyString(address.pincode)) {
        newErrorConfig["pincode"] = "Empty pincode";
      }
    }
    if (new_step === STEP.REVIEW) {
      if (isEmptyString(user.first_name)) {
        newErrorConfig["user_first_name"] = "Empty First Name";
      }
      if (isEmptyString(user.last_name)) {
        newErrorConfig["user_last_name"] = "Empty Last Name";
      }
    }
    setErrorConfig(newErrorConfig);
    if (Object.keys(newErrorConfig).length) {
      return;
    }
    setStep(new_step);
  };
  const [transport, setTransport] = useState<Transport>({
    id: null,
    name: "",
    email: "",
    phone: "",
  });

  const updateTransport: UpdateEntityFn<Transport> = (key, value) => {
    const new_transport = { ...transport };
    new_transport[key] = value;
    setTransport(new_transport);
  };

  const [address, setAddress] = useState<Address>({
    id: null,
    city: "",
    state: "",
    pincode: "",
    address_1: "",
    address_2: "",
    country: "IN",
  });
  const updateAddress: UpdateEntityFn<Address> = (key, value) => {
    const new_address = { ...address };
    new_address[key] = value;
    setAddress(new_address);
  };
  const [user, setUser] = useState<Partial<User>>({});

  const updateUser: UpdateEntityFn<User> = (key, value) => {
    const new_user = { ...user };
    new_user[key] = value;
    setUser(new_user);
  };

  return {
    step,
    updateStep,
    transport,
    updateTransport,
    address,
    updateAddress,
    user,
    updateUser,
    errorConfig,
  };
};
