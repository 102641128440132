import { title } from 'process';
import React from 'react';
import StarIcon from '@mui/icons-material/Star';
const Pricing = () => {
  const subscriptionList = [
    {
      title: "Standard Plan",
      price: 0,
      usps: [
        {
          title: "Review Visiblity",
          tier: 3
        },
        {
          title: "Load Search",
          tier: 3
        },
        {
          title: "Transport Database Acess",
          tier: 2
        },
        {
          title: "Publish Rating",
          tier: 2
        },
        {
          title: "Transport Verification",
          tier: 2
        },
        {
          title: "Publish Load",
          tier: 2
        },
      ]
    },
    {
      title: "Premium Plan",
      price: 500,
      usps: [
        {
          title: "Review Visiblity",
          tier: 1
        },
        {
          title: "Load Search",
          tier: 1
        },
        {
          title: "Transport Database Acess",
          tier: 1
        },
        {
          title: "Publish Rating",
          tier: 1
        },
        {
          title: "Transport Verification",
          tier: 1
        },
        {
          title: "Publish Load",
          tier: 1
        },
      ]
    }
  ];
  const tierClasses: { [key: number]: string } = {
    1: 'yellow-800',
    2: 'orange-700',
    3: 'green-4'
  };

  return (
    <div className='bg-neutral-4 py-96 px-12 mt-32 txt-align-center'>
      <div className='fs-32 fw-600 neutral-10'>Subscription Models</div>
      <div className='neutral-7 mt-8'>Flexible pricing for all tranporation needs</div>
      <div className="d-flex gap-32 bg-neutral-4 mt-32 justify-content-center flex-wrap">
        {subscriptionList.map((subscription, index) =>
          <div key={index} className=" bg-neutral-1 flex-col rounded-16 elevation-2  p-48  fw-500" >
            <div className='orange-4 fs-24'>{subscription.title}</div>
            <div className="flex-grow fs-18 mt-16">
              {subscription.usps.map((usp, uspIndex) =>
                <div key={index * 10 + uspIndex} className={`mt-8 d-flex gap-8 align-items-center ${tierClasses[usp.tier]}`}>
                  <StarIcon />
                  {usp.title}
                </div>)}
            </div>
            <div className='orange-4 mt-24'>
              <div className='fs-24'>INR {subscription.price}</div>
              <div className='fs-14'>Monthly</div>
            </div>
          </div>)}
      </div>
      <div className='d-flex gap-16 justify-content-center flex-wrap'>
        <div className='d-flex align-items-center gap-8 mt-32 fs-20 yellow-800  '><StarIcon className='fs-32' />Premium</div>
        <div className='d-flex align-items-center gap-8 mt-32 fs-20 orange-700'><StarIcon className='fs-32' />Limited</div>
        <div className='d-flex align-items-center gap-8 mt-32 fs-20 green-4 '><StarIcon className='fs-32' />Standard</div>
      </div>
    </div>
  );
};
export default Pricing;