import { useCallback, useState } from "react";
import { TransportCall } from "../../types";
import { UpdateEntityFn } from "./types";
import { isEmpty } from "../../util/utils";
import { debounce } from "../../util/debounce";

export const useStateValues = () => {
  const [errorConfig, setErrorConfig] = useState<{ [key: string]: string }>({});
  const [transport_call, setTransportCall] = useState<Partial<TransportCall>>({
    status: 'INIT',
    is_active: false
  });
  const validate = useCallback(() => {
    const newErrorConfig: { [key: string]: string } = {};
    if (isEmpty(transport_call.transport)) {
      newErrorConfig["transport"] = "Empty Transport";
    }
    if (isEmpty(transport_call.status)) {
      newErrorConfig["status"] = "Empty Status";
    }
    if (isEmpty(transport_call.comment)) {
      newErrorConfig["comment"] = "Empty Comment";
    }
    setErrorConfig(newErrorConfig);
    return Object.keys(newErrorConfig).length;
  }, [transport_call]);

  const updateTransportCall = useCallback<UpdateEntityFn<Partial<TransportCall>>>(
    (key, value) => {
      const new_transport_call = { ...transport_call };
      new_transport_call[key] = value;
      setTransportCall(new_transport_call);
    },
    [transport_call]
  );

  return {
    transport_call,
    updateTransportCall: debounce(updateTransportCall),
    errorConfig,
    validate,
  };
};
