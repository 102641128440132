import { axiosInstance } from "../../../api";
import {
  PaginatedResponse,  
  SalesLead,  
  Transport,
} from "../../types";

export const listTranportApi = ({
  company_name,
  city,
  state,
  rating,
  pincode,
  order_by = "created",
  added_by,
  contact_by,
  status,
  page = 1,
  page_size = 5,
}: {
  company_name?: string;
  city?: string;
  state?: string;
  rating?: number;
  pincode?: number;
  order_by?: string;
  added_by?: number;
  contact_by?: number;
  status?: string;
  page?: number;
  page_size?: number;
}) => {
  const urlParams = new URLSearchParams();
  urlParams.set("order_by", order_by);
  urlParams.set("page", String(page));
  urlParams.set("limit", String(page_size));

  if (company_name) {
    urlParams.set("company_name", company_name);
  }  
  if (city) {
    urlParams.set("city", city);
  }
  if (state) {
    urlParams.set("state", state);
  }  
  if (rating) {
    urlParams.set("rating", String(rating));
  }  
  if (pincode) {
    urlParams.set("pincode", String(pincode));
  }
  if (added_by) {
    urlParams.set("added_by", String(added_by));
  }
  if (status) {
    urlParams.set("status", status);
  }
  if (contact_by) {
    urlParams.set("contact_by", String(contact_by));
  }
  return axiosInstance()
    .get<PaginatedResponse<SalesLead>>(
      `/backoffice/sales/list-detail/?page=1&${urlParams.toString()}`
    )
    .then((response) => response.data);
};


export const updateSalesLeadApi = (
  lead_id: number,
  data: { [key: string]: string }
) => {
  return axiosInstance()
    .patch<Transport>(`/backoffice/sales/${lead_id}/`, data)
    .then((response) => response.data);
};


