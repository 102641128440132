import { useState } from "react";
import { Address } from "../../types";
import { isEmptyString } from "../../util/utils";
import { UpdateEntityFn } from "../AddTransport/types";

export const useStateValues = () => {
  const [errorConfig, setErrorConfig] = useState<{ [key: string]: string }>({});
  const validate = () => {
    const newErrorConfig: { [key: string]: string } = {};
    if (isEmptyString(address.city)) {
      newErrorConfig["city"] = "Empty City";
    }
    if (isEmptyString(address.state)) {
      newErrorConfig["state"] = "Empty State";
    }

    if (isEmptyString(address.pincode)) {
      newErrorConfig["pincode"] = "Pincode";
    }    

    setErrorConfig(newErrorConfig);
    return Object.keys(newErrorConfig).length;
  };
  const [address, setAddress] = useState<Partial<Address>>({});
  
  const updateAddress: UpdateEntityFn<Address> = (key, value) => {
    const new_address = { ...address };
    new_address[key] = value;
    setAddress(new_address);
  }; 

  return {   
    address,
    updateAddress,
    errorConfig,
    validate
  };
};
