import AdminDashboard from "./components/AdminDashboard";
import Landing from "./components/Landing";
import Load from "./components/Load";
import Review from "./components/Review";

import { RouteInterface } from "./types";

export const PUBLIC_ROUTES: RouteInterface[] = [
    {
        path: "/",
        component: Landing,
    },
    {
        path: "/load-search",
        component: Load,
    },
];
export const PROTECTED_ROUTES: RouteInterface[] = [
    {
        path: "/submit-review",
        component: Review
    },
    {
        path: "/admin/*",
        component: AdminDashboard
    }
];