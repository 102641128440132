import { Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useStateValues } from "./useStateValues";
import { axiosInstance } from "../../../api";
import { useDispatch } from "react-redux";
import { Address, Communication, Load, Transport } from "../../types";
import { setMessage } from "../../../redux/slices/alertSlice";
import CustomAutoComplete from "../../common/CustomAutoComplete";
import { capitalizeFirstLetter } from "../../util/utils";
import UserAvatar from "../../common/UserAvatar";
import { CustomAutoCompleteSelected } from "../../common/CustomAutoComplete/types";
import { TextField } from "@mui/material";
import { setAddEntity } from "../../../redux/slices/entitySlice";
import { searchTranportApi } from "../TransportDashboard/api";
import { filterAddressApi } from "../AddAddress/api";
import { Add } from "@mui/icons-material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { filterCommunicationApi } from "../Communication/api";

export const AddLoad = ({ onClose }: { onClose?: () => void }) => {
  const dispatch = useDispatch();

  const { load, updateLoad, errorConfig, validate } = useStateValues();

  const handleAddLoad = async () => {
    if (validate()) {
      console.log("not validate here", errorConfig);
      return;
    }
    const data = {
      ...load,
    };
    try {
      await axiosInstance().post<Load>("backoffice/load/", data);
      dispatch(setMessage("Load Added"));
      onClose?.();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <div className="bg-neutral-3 d-flex p-16 align-items-center">
        <div className="fs-18 flex-grow">Add Load</div>
        <div className="pointer" onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      <div className="p-16 overflow-y-scroll" style={{ height: "70vh" }}>
        <div className="d-flex gap-8">
          <Button
            className="d-flex bg-orange-4 neutral-1"
            onClick={() =>
              dispatch(setAddEntity({ key: "transport", value: true }))
            }
          >
            <Add />
            <span>Transport</span>
          </Button>
          <Button
            className="d-flex bg-orange-4 neutral-1"
            onClick={() =>
              dispatch(setAddEntity({ key: "address", value: true }))
            }
          >
            <Add />
            <span>Address</span>
          </Button>
        </div>
        <CustomAutoComplete
          className="mt-8"
          width={"200px"}
          onSelect={(transport: Transport | Transport[] | null) => {
            if (transport) {
              updateLoad("load_owner", (transport as Transport).id as number);
            }
          }}
          getOptionLabel={(transport: Transport) => {
            return capitalizeFirstLetter(transport.name);
          }}
          placeholder="Transport"
          renderOptionFn={(transport: Transport) => (
            <UserAvatar user={{ first_name: transport.name }} />
          )}
          dataSource={(text: string) => searchTranportApi({ name: text })}
          errorMessage={errorConfig["load_owner_id"]}
        />
        <CustomAutoComplete
          className="mt-8"
          width={"200px"}
          onSelect={(address: CustomAutoCompleteSelected<Address>) => {
            if (address) {
              updateLoad("origin", (address as Address).id as number);
            }
          }}
          getOptionLabel={(address: Address) => {
            return `${address.address_1}, ${address.city}, ${address.state}, ${address.pincode}`;
          }}
          placeholder="Origin"
          renderOptionFn={(address: Address) => (
            <Box>
              {`${address.address_1}, ${address.city}, ${address.state}, ${address.pincode}`}
            </Box>
          )}
          dataSource={(text: string) => filterAddressApi({ text })}
          errorMessage={errorConfig["origin_id"]}
        />
        <CustomAutoComplete
          className="mt-8"
          width={"200px"}
          onSelect={(address: CustomAutoCompleteSelected<Address>) => {
            if (address) {
              updateLoad("destination", (address as Address).id as number);
            }
          }}
          getOptionLabel={(address: Address) => {
            return `${address.address_1}, ${address.city}, ${address.state}, ${address.pincode}`;
          }}
          placeholder="Destination"
          renderOptionFn={(address: Address) => (
            <Box>
              {`${address.address_1}, ${address.city}, ${address.state}, ${address.pincode}`}
            </Box>
          )}
          dataSource={(text: string) => filterAddressApi({ text })}
        />
        <CustomAutoComplete
          className="mt-8"
          width={"200px"}
          onSelect={(address: CustomAutoCompleteSelected<Communication>) => {
            if (address) {
              updateLoad("communication", (address as Communication).id as number);
            }
          }}
          getOptionLabel={(communication: Communication) => {
            return `${communication.mode}, ${communication.name}`;
          }}
          placeholder="Communication"
          renderOptionFn={(communication: Communication) => (
            <Box>
              {`${communication.mode}, ${communication.name}`}
            </Box>
          )}
          dataSource={(text: string) => filterCommunicationApi({ text })}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Min Guarentee"
          variant="outlined"
          type="number"
          onChange={(e) => updateLoad("min_guarentee", Number(e.target.value))}
          helperText={errorConfig["min_guarentee"]}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Rating"
          variant="outlined"
          onChange={(e) => updateLoad("rating", Number(e.target.value))}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Rate/Ton"
          type="number"
          variant="outlined"
          onChange={(e) => updateLoad("amount_ton", Number(e.target.value))}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Total Amount"
          type="number"
          variant="outlined"
          onChange={(e) => updateLoad("amount_total", Number(e.target.value))}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Distance"
          type="number"
          variant="outlined"
          onChange={(e) => updateLoad("distance", Number(e.target.value))}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Vehicle Required"
          type="number"
          variant="outlined"
          onChange={(e) =>
            updateLoad("vehicle_required", Number(e.target.value))
          }
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Vehicle Capacity"
          variant="outlined"
          type="number"
          onChange={(e) =>
            updateLoad("vehicle_required_capacity_ton", Number(e.target.value))
          }
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Loading Charge"
          type="number"
          variant="outlined"
          onChange={(e) => updateLoad("loading_charge", Number(e.target.value))}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Unloading Charge"
          variant="outlined"
          onChange={(e) =>
            updateLoad("unloading_charge", Number(e.target.value))
          }
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Comment"
          variant="outlined"
          onChange={(e) => updateLoad("comment", e.target.value)}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={load.is_public}
                onChange={(e) => updateLoad("is_public", e.target.checked)}
              />
            }
            label="Public Load"
          />
        </FormGroup>
      </div>
      <div className="p-16 mt-16">
        <div className="d-flex gap-8 justify-content-end">
          <Button
            className="flex-half"
            color="primary"
            variant="contained"
            onClick={handleAddLoad}
          >
            Add Load
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddLoad;
