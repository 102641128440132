import React from 'react';
import { TextField } from '@mui/material';
import CustomRating from '../../common/CustomRating';
import { useDispatch } from 'react-redux';
import { setReview, useReview } from '../../../redux/slices/reviewSlice';

const ReviewSection = ({
    className
}: {
    className: string
}) => {
    const dispatch = useDispatch();
    const review = useReview();
    return (
        <div className={`d-flex flex-column gap-4 ${className}`}>
            <div className='fs-24 fw-700 txt-align-center'>Submit Your Review</div>
            <div className='mt-16 fs-16 fw-600'>
                <div>Transport Rating</div>
                <CustomRating className='mt-8' onChange={(value) => dispatch(setReview({ rating: value }))} />
            </div>
            <div>
                <div className='mt-16 fs-16 fw-600 '>Transaction Info</div>
                <div className='d-flex gap-16 align-items-center'>
                    <TextField className='mt-8 bg-neutral-2'
                        label="Transaction Amount"
                        variant="outlined"
                        type="number"
                        value={review?.review?.transaction_amount}
                        onChange={(e) => dispatch(setReview({ transaction_amount: e.target.value }))}
                    />
                    <TextField className='mt-8 bg-neutral-2'
                        label="Lost/Fraud Amount"
                        type="number"
                        variant="outlined"
                        value={review?.review?.fraud_amount}
                        onChange={(e) => dispatch(setReview({ fraud_amount: e.target.value }))}
                    />
                </div>
            </div>
            <div className='d-flex flex-column gap-4'>
                <div className='mt-16 fs-16 fw-600 '>Review</div>
                <TextField className='mt-8 bg-neutral-2'
                    label="Provide Review"
                    multiline
                    rows={8}
                    variant="outlined"
                    value={review?.review?.comment}
                    onChange={(e) => dispatch(setReview({ comment: e.target.value }))}
                    helperText={review?.errorState['comment']}
                />
            </div>
        </div>
    );
};

export default ReviewSection;
