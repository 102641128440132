import React from 'react'
import { useReview } from '../../../../redux/slices/reviewSlice'
export const Transportdetails = () => {
    const review = useReview();
    return (
        <div className='bg-blue-700 neutral-1 p-24'>
            <div className='fs-16 fw-600 txt-align-center mt-8'>Transport Details</div>
            <div className='d-flex mt-16'>
                <div className='flex-grow'>
                    <div className='fs-16 '>Transporter Name: <span className='bold'>{review?.transport?.name}</span></div>
                    <div className='fs-16'>Phone Number:<span className='bold'>{review?.transport?.phone}</span></div>
                    <div className='fs-16 '>Address:<span className='bold'>{review?.transport?.address?.address_1}</span></div>
                </div>
                {review?.transport?.users?.length ? <div className='flex-grow'>
                    <div>Contact Person (s)</div>
                    {review.transport.users.map((user, index) => <div>{`${user.first_name} ${user.last_name}`}</div>)}
                </div> : null}
            </div>
        </div>

    )
}
export default Transportdetails;
