import React from "react";
import { CustomAvatar } from "../CustomAvatar";

const UserAvatar = ({
  user,
  className,
}: {
  user?: { first_name: string; last_name?: string };
  className?: string;
}) => {
  return (
    <div className="d-flex gap-8 align-items-center">
      <CustomAvatar
        user={user}
        className="my-8 pointer"
      />
      <div>{user?.first_name}</div>
    </div>
  );
};

export default UserAvatar;
