import { Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useStateValues } from "./useStateValues";
import { axiosInstance } from "../../../api";
import { useDispatch } from "react-redux";
import { Address, NumStrNull, Transport, Trip } from "../../types";
import { setMessage } from "../../../redux/slices/alertSlice";
import CustomAutoComplete from "../../common/CustomAutoComplete";
import { capitalizeFirstLetter } from "../../util/utils";
import UserAvatar from "../../common/UserAvatar";
import { CustomAutoCompleteSelected } from "../../common/CustomAutoComplete/types";
import { TextField } from "@mui/material";
import { setAddEntity } from "../../../redux/slices/entitySlice";
import { searchTranportApi } from "../TransportDashboard/api";
import { filterAddressApi } from "../AddAddress/api";
import { Add } from "@mui/icons-material";
import { TRIP_STATUS, TripStatus } from "../TripDashboard/types";

export const AddTrip = ({ onClose }: { onClose?: () => void }) => {
  const dispatch = useDispatch();

  const { trip, updateTrip, errorConfig, validate } = useStateValues();

  const handleAddTrip = async () => {
    if (validate()) {
      console.log("not validate here", errorConfig);
      return;
    }
    const data = {
      ...trip,
    };
    try {
      await axiosInstance().post<Trip>("backoffice/trip/", data);
      dispatch(setMessage("Trip Added"));
      onClose?.();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <div className="bg-neutral-3 d-flex p-16 align-items-center">
        <div className="fs-18 flex-grow">Add Trip</div>
        <div className="pointer" onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      <div className="p-16 overflow-y-scroll" style={{ height: "70vh" }}>
        <div className="d-flex gap-8">
          <Button
            className="d-flex bg-orange-4 neutral-1"
            onClick={() =>
              dispatch(setAddEntity({ key: "transport", value: true }))
            }
          >
            <Add />
            <span>Transport</span>
          </Button>
          <Button
            className="d-flex bg-orange-4 neutral-1"
            onClick={() =>
              dispatch(setAddEntity({ key: "address", value: true }))
            }
          >
            <Add />
            <span>Address</span>
          </Button>
        </div>
        <CustomAutoComplete
          className="mt-8"
          width={"200px"}
          onSelect={(transport: CustomAutoCompleteSelected<Transport>) => {
            if (transport) {
              updateTrip("transport", (transport as Transport).id as number);
            }
          }}
          getOptionLabel={(transport: Transport) => {
            return capitalizeFirstLetter(transport.name);
          }}
          placeholder="Transport"
          renderOptionFn={(transport: Transport) => (
            <UserAvatar user={{ first_name: transport.name }} />
          )}
          dataSource={(text: string) => searchTranportApi({ name: text })}
          errorMessage={errorConfig["transport"]}
        />
        <CustomAutoComplete
          className="mt-8"
          width={"200px"}
          onSelect={(address: CustomAutoCompleteSelected<Address>) => {
            if (address) {
              updateTrip("origin", (address as Address).id as number);
            }
          }}
          getOptionLabel={(address: Address) => {
            return `${address.city}, ${address.state}`;
          }}
          placeholder="Origin"
          renderOptionFn={(address: Address) => (
            <Box>
              {`${address.city}, ${address.state}`}
            </Box>
          )}
          dataSource={(text: string) => filterAddressApi({ text })}
          errorMessage={errorConfig["origin"]}
        />
        <CustomAutoComplete
          className="mt-8"
          width={"200px"}
          onSelect={(address: CustomAutoCompleteSelected<Address>) => {
            if (address) {
              updateTrip("destination", (address as Address).id as number);
            }
          }}
          getOptionLabel={(address: Address) => {
            return `${address.city}, ${address.state}`;
          }}
          placeholder="Destination"
          renderOptionFn={(address: Address) => (
            <Box>
              {`${address.city}, ${address.state}`}
            </Box>
          )}
          dataSource={(text: string) => filterAddressApi({ text })}
          errorMessage={errorConfig["destination"]}
        />
        {/* <CustomAutoComplete
          className="mt-8"
          width={"200px"}
          defaultValue={{ id: trip.status as NumStrNull }}
          onSelect={(status: CustomAutoCompleteSelected<TripStatus>) =>
            updateTrip("status", (status as TripStatus).id as string)
          }
          getOptionLabel={(status: TripStatus) => status.id as string}
          placeholder="Status"
          renderOptionFn={(status: TripStatus) => <div>{status.id}</div>}
          dataList={TRIP_STATUS}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Loading Charge"
          type="number"
          variant="outlined"
          onChange={(e) => updateTrip("loading_charge", Number(e.target.value))}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Unloading Charge"
          variant="outlined"
          onChange={(e) =>
            updateTrip("unloading_charge", Number(e.target.value))
          }
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Comission"
          variant="outlined"
          onChange={(e) => updateTrip("comission", Number(e.target.value))}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Min Guarentee"
          variant="outlined"
          type="number"
          onChange={(e) => updateTrip("min_guarentee", Number(e.target.value))}
          helperText={errorConfig["min_guarentee"]}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Rate/Ton"
          type="number"
          variant="outlined"
          onChange={(e) => updateTrip("amount_ton", Number(e.target.value))}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Total Amount"
          type="number"
          variant="outlined"
          onChange={(e) => updateTrip("amount_total", Number(e.target.value))}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Distance"
          type="number"
          variant="outlined"
          onChange={(e) => updateTrip("distance", Number(e.target.value))}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Total Distance"
          type="number"
          variant="outlined"
          onChange={(e) => updateTrip("total_distance", Number(e.target.value))}
        /> */}
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Vehicle"
          variant="outlined"
          onChange={(e) => updateTrip("vehicle", e.target.value)}
        />
        {/* <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Comment"
          variant="outlined"
          onChange={(e) => updateTrip("comment", e.target.value)}
        /> */}
      </div>
      <div className="p-16 mt-16">
        <div className="d-flex gap-8 justify-content-end">
          <Button
            className="flex-half"
            color="primary"
            variant="contained"
            onClick={handleAddTrip}
          >
            Add Trip
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddTrip;
