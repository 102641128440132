import { axiosInstance } from "../../../api";
import {
  Address,
  PaginatedResponse,
  Transport,
  TransportDetail,
} from "../../types";
import { isEmpty } from "../../util/utils";

export const searchTranportApi = ({
  name,
  phone,
  city,
  state,
  rating,
  transaction_amount,
  fraud_amount,
  page = 1,
  page_size = 5,
}: {
  name?: string;
  phone?: string;
  city?: string;
  state?: string;
  rating?: number;
  transaction_amount?: number;
  fraud_amount?: number;
  page?: number;
  page_size?: number;
}) => {
  const urlParams = new URLSearchParams();
  if (phone) {
    urlParams.set("phone", phone);
  }
  if (city) {
    urlParams.set("city", city);
  }
  if (state) {
    urlParams.set("state", state);
  }
  if (transaction_amount) {
    urlParams.set("transaction_amount", String(transaction_amount));
  }
  if (fraud_amount) {
    urlParams.set("fraud_amount", String(fraud_amount));
  }
  if (rating) {
    urlParams.set("rating", String(rating));
  }
  if (name) {
    urlParams.set("name", name);
  }
  urlParams.set("page", String(page));
  urlParams.set("limit", String(page_size));
  return axiosInstance()
    .get<PaginatedResponse<Transport>>(
      `/backoffice/transport/?page=1&${urlParams.toString()}`
    )
    .then((response) => response.data);
};

export const listTranportApi = ({
  filter,
  order_by = "created",
  page = 1,
  page_size = 5,
}: {
  filter: { [key: string]: unknown };
  order_by?: string;
  page?: number;
  page_size?: number;
}) => {
  const urlParams = new URLSearchParams();
  urlParams.set("order_by", order_by);
  urlParams.set("page", String(page));
  urlParams.set("limit", String(page_size));
  for (let key in filter) {
    if(isEmpty(filter[key])) {
      continue;
    }
    urlParams.set(key, String(filter[key]));
  }
  return axiosInstance()
    .get<PaginatedResponse<TransportDetail>>(
      `/backoffice/transport/list-detail/?page=1&${urlParams.toString()}`
    )
    .then((response) => response.data);
};

export const updateTransportApi = (
  tranport_id: number,
  data: Partial<Transport>
) => {
  return axiosInstance()
    .patch<Transport>(`/backoffice/transport/${tranport_id}/`, data)
    .then((response) => response.data);
};
