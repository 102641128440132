import { axiosInstance } from "../../../api";
import { Address, PaginatedResponse } from "../../types";

export const filterCityApi = ({
  city,
  page = 1,
  page_size = 5,
}: {
  city?: string;
  page?: number;
  page_size?: number;
}) => {
  const urlParams = new URLSearchParams();
  if (city) {
    urlParams.set("city", city);
  }
  urlParams.set("page", String(page));
  urlParams.set("limit", String(page_size));
  return axiosInstance()
    .get<PaginatedResponse<Address>>(
      `/backoffice/address/city/?page=1&${urlParams.toString()}`
    )
    .then((response) => response.data);
};

export const filterAddressApi = ({
  text,
  page = 1,
  page_size = 5,
}: {
  text?: string;
  page?: number;
  page_size?: number;
}) => {
  const urlParams = new URLSearchParams();
  if (text) {
    urlParams.set("text", text);
  }
  urlParams.set("page", String(page));
  urlParams.set("limit", String(page_size));
  return axiosInstance()
    .get<PaginatedResponse<Address>>(
      `/backoffice/address/?page=1&${urlParams.toString()}`
    )
    .then((response) => response.data);
};

export const filterStateApi = ({
  state,
  page = 1,
  page_size = 5,
}: {
  state?: string;
  page?: number;
  page_size?: number;
}) => {
  const urlParams = new URLSearchParams();
  if (state) {
    urlParams.set("state", state);
  }
  urlParams.set("page", String(page));
  urlParams.set("limit", String(page_size));
  return axiosInstance()
    .get<PaginatedResponse<Address>>(
      `/backoffice/address/state/?page=1&${urlParams.toString()}`
    )
    .then((response) => response.data);
};

export const updateAddressApi = (
  address_id: number,
  data: Partial<Address>
) => {
  return axiosInstance()
    .patch<Address>(`/backoffice/address/${address_id}/`, data)
    .then((response) => response.data);
};
