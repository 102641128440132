import { axiosInstance } from "../../../api";
import { PaginatedResponse, User } from "../../types";

export const filterUserApi = ({
  text,
  page = 1,
  page_size = 5,
}: {
  text?: string;
  page?: number;
  page_size?: number;
}) => {
  const urlParams = new URLSearchParams();
  if (text) {
    urlParams.set("text", text);
  }
  urlParams.set("page", String(page));
  urlParams.set("limit", String(page_size));
  return axiosInstance()
    .get<PaginatedResponse<User>>(
      `/backoffice/user/?page=1&${urlParams.toString()}`
    )
    .then((response) => response.data);
};
