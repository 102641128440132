import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useStateValues } from "./useStateValue";
import CustomAutoComplete from "../../common/CustomAutoComplete";
import { Address, Communication, Load, Transport, User } from "../../types";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { capitalizeFirstLetter, convertUTCToLocal } from "../../util/utils";
import { filterLoadCityApi } from "./api";
import UserAvatar from "../../common/UserAvatar";
import { CustomAutoCompleteSelected } from "../../common/CustomAutoComplete/types";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TablePagination,
  TextField,
} from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { searchTranportApi } from "../TransportDashboard/api";
import { setAddEntity } from "../../../redux/slices/entitySlice";
import { useDispatch } from "react-redux";
import { filterAddressApi } from "../AddAddress/api";
import "./index.scss";
import { filterCommunicationApi } from "../Communication/api";
import { filterUserApi } from "../User/api";
import { setMessage } from "../../../redux/slices/alertSlice";
const LoadDashboard = () => {
  const dispatch = useDispatch();
  const {
    loads,
    updateFilter,
    page,
    setPage,
    page_size,
    setPageSize,
    count,
    updateLoad,
    order_by,
    updateSortKey,
  } = useStateValues();

  const columns = [
    { name: "Id", sort_key: "id", width: "100px" },
    { name: "Created", sort_key: "created" },
    { name: "Transport", sort_key: "load_owner.name", width: "300px" },
    { name: "Transport Rating", sort_key: "", width: "150px" },
    { name: "Added By", sort_key: "added_by.id", width: "150px" },
    { name: "Load Rating", sort_key: "", width: "150px" },
    { name: "Origin", sort_key: "origin.city", width: "200px" },
    { name: "Destination", sort_key: "destination.city", width: "200px" },
    { name: "Channel", sort_key: "", width: "200px" },
    { name: "Distance", sort_key: "distance", width: "200px" },
    { name: "Min Guarentee", sort_key: "min_guarentee", width: "200px" },
    { name: "Rate Ton", sort_key: "amount_ton", width: "200px" },
    { name: "Total Rate", sort_key: "amount_total", width: "150px" },
    { name: "Vehicle", sort_key: "" },
    { name: "Vehicle Capacity", sort_key: "" },
    { name: "Min Amount", sort_key: "" },
    { name: "Max Amount", sort_key: "" },
    { name: "Public", sort_key: "" },
    { name: "Fraud", sort_key: "" },
    { name: "Expired", sort_key: "" },
    { name: "Copy", sort_key: "" },
  ];

  const copyLoadInfo = (load: Load) => {
    const text = `Load Info: 
      Transport: ${(load.load_owner as Transport)?.name}
      Load Type: ${load.load_type || "NA"}
      Min Guarentee: ${load.min_guarentee || " - "}T
      Rate: ${load.amount_ton || "NA"}/T
      Join: ${(load.communication as Communication)?.link}
    `;
    navigator.clipboard
      .writeText(text)
      .then(function () {
        dispatch(setMessage("Load Copied"));
      })
      .catch(function (err) {
        console.error("Could not copy text: ", err);
      });
  };

  return (
    <div className="p-8">
      <div className="d-flex gap-8 flex-wrap">
        <CustomAutoComplete
          className=""
          width={"200px"}
          onSelect={(transport: Transport | Transport[] | null) => {
            if (transport) {
              updateFilter("transport_id", (transport as Transport).id);
            } else {
              updateFilter("transport_id", null);
            }
          }}
          getOptionLabel={(load: Transport) => {
            return capitalizeFirstLetter(load.name);
          }}
          placeholder="Transport"
          renderOptionFn={(load: Transport) => (
            <UserAvatar user={{ first_name: load.name }} />
          )}
          dataSource={(text: string) => searchTranportApi({ name: text })}
        />
        <TextField
          size="small"
          className="bg-neutral-2 "
          placeholder="Destination City"
          variant="outlined"
          onChange={(e) => updateFilter("origin_city", e.target.value)}
        />        
        <TextField
          size="small"
          className="bg-neutral-2 "
          placeholder="Destination City"
          variant="outlined"
          onChange={(e) => updateFilter("destination_city", e.target.value)}
        />        
        <CustomAutoComplete
          className=""
          width={"200px"}
          onSelect={(user: CustomAutoCompleteSelected<User>) => {
            if (user) {
              updateFilter("added_by_id", (user as User)?.id);
            } else {
              updateFilter("added_by_id", null);
            }
          }}
          getOptionLabel={(user: User) =>
            `${user.first_name} ${user.last_name}`
          }
          placeholder="Added By"
          renderOptionFn={(user: User) => (
            <div>
              {user.first_name} {user.last_name}
            </div>
          )}
          dataSource={(text: string) => filterUserApi({ text })}
        />
        <TextField
          size="small"
          className="bg-neutral-2 "
          placeholder="Phone"
          variant="outlined"
          onChange={(e) => updateFilter("phone", e.target.value)}
        />
        <TextField
          size="small"
          className=" bg-neutral-2 "
          placeholder="Transport Rating"
          variant="outlined"
          type="number"
          onChange={(e) =>
            updateFilter("transport_rating", Number(e.target.value))
          }
        />
        <TextField
          size="small"
          className=" bg-neutral-2 "
          placeholder="Load Rating"
          variant="outlined"
          type="number"
          onChange={(e) => updateFilter("load_rating", Number(e.target.value))}
        />
        <TextField
          size="small"
          className=" bg-neutral-2 "
          placeholder="Pincode"
          variant="outlined"
          type="number"
          onChange={(e) => updateFilter("pincode", Number(e.target.value))}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => updateFilter("is_expired", e.target.checked)}
              />
            }
            label="Expired"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => updateFilter("is_public", e.target.checked)}
              />
            }
            label="Public"
          />
        </FormGroup>
      </div>
      <div className="mt-8">
        <TableContainer sx={{ backgroundColor: "white" }} component={Paper}>
          <div className="d-flex gap-16 flex-wrap align-items-center px-16">
            <PlaylistAddIcon
              onClick={() =>
                dispatch(setAddEntity({ key: "load", value: true }))
              }
              className="orange-3 pointer"
            />
            <TablePagination
              rowsPerPageOptions={loads.length ? [10, 20, 50] : []}
              component="div"
              count={count}
              rowsPerPage={page_size}
              page={page}
              onPageChange={(_, page) => setPage(page)}
              onRowsPerPageChange={(e) =>
                setPageSize(parseInt(e.target.value, 10))
              }
            />
          </div>
          <Table size="small" className="load-table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.name}
                    align="left"
                    width={column.width || "100px"}
                  >
                    <div
                      className="d-flex gap-8 align-items-center pointer"
                      onClick={(e) => {
                        updateSortKey(column.sort_key);
                      }}
                    >
                      <span>{column.name}</span>
                      {column.sort_key.length &&
                      order_by.includes(column.sort_key) ? (
                        <span>
                          <SwapVertIcon />
                        </span>
                      ) : null}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loads.map((load, index) => (
                <React.Fragment key={load.id}>
                  <TableRow
                    key={load.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      className="pointer"
                      align="left"
                      onClick={() => copyLoadInfo(load)}
                    >
                      {load.id}
                    </TableCell>
                    <TableCell align="left">
                      {convertUTCToLocal(load.created!)}
                    </TableCell>
                    <TableCell align="left">
                      {(load.load_owner as Transport)?.name}
                    </TableCell>
                    <TableCell align="left">
                      {(load.load_owner as Transport)?.rating}
                    </TableCell>
                    <TableCell align="left">
                      <UserAvatar user={load.added_by as User} />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        fullWidth
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="Load Rating"
                        variant="outlined"
                        type="text"
                        defaultValue={load.rating}
                        onChange={(e) =>
                          updateLoad({
                            index,
                            key: "rating",
                            value: Number(e.target.value),
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <CustomAutoComplete
                        className=""
                        width={"200px"}
                        defaultValue={load.origin as Address}
                        onSelect={(
                          address: CustomAutoCompleteSelected<Address>
                        ) => {
                          if (address) {
                            updateLoad({
                              index,
                              key: "origin",
                              value: (address as Address).id as number,
                            });
                          }
                        }}
                        getOptionLabel={(address: Address) => {
                          return `${address.address_1}, ${address.city}, ${address.state}, ${address.pincode}`;
                        }}
                        placeholder="Origin"
                        renderOptionFn={(address: Address) => (
                          <Box>
                            {`${address.address_1}, ${address.city}, ${address.state}, ${address.pincode}`}
                          </Box>
                        )}
                        dataSource={(text: string) =>
                          filterAddressApi({ text })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <CustomAutoComplete
                        className=""
                        width={"200px"}
                        defaultValue={load.destination as Address}
                        onSelect={(
                          address: CustomAutoCompleteSelected<Address>
                        ) => {
                          if (address) {
                            updateLoad({
                              index,
                              key: "destination",
                              value: (address as Address).id as number,
                            });
                          }
                        }}
                        getOptionLabel={(address: Address) => {
                          return `${address.address_1}, ${address.city}, ${address.state}, ${address.pincode}`;
                        }}
                        placeholder="Destination"
                        renderOptionFn={(address: Address) => (
                          <Box>
                            {`${address.address_1}, ${address.city}, ${address.state}, ${address.pincode}`}
                          </Box>
                        )}
                        dataSource={(text: string) =>
                          filterAddressApi({ text })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <CustomAutoComplete
                        className="mt-8"
                        width={"200px"}
                        defaultValue={load.communication as Communication}
                        onSelect={(
                          address: CustomAutoCompleteSelected<Communication>
                        ) => {
                          if (address) {
                            updateLoad({
                              index,
                              key: "communication",
                              value: (address as Communication).id as number,
                            });
                          }
                        }}
                        getOptionLabel={(communication: Communication) => {
                          return `${communication.mode}, ${communication.name}`;
                        }}
                        placeholder="Communication"
                        renderOptionFn={(communication: Communication) => (
                          <Box>
                            {`${communication.mode}, ${communication.name}`}
                          </Box>
                        )}
                        dataSource={(text: string) =>
                          filterCommunicationApi({ text })
                        }
                      />{" "}
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        fullWidth
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="Distance"
                        variant="outlined"
                        type="text"
                        defaultValue={load.distance}
                        onChange={(e) =>
                          updateLoad({
                            index,
                            key: "distance",
                            value: Number(e.target.value),
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        fullWidth
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="Min Guarentee"
                        variant="outlined"
                        type="text"
                        defaultValue={load.min_guarentee}
                        onChange={(e) =>
                          updateLoad({
                            index,
                            key: "min_guarentee",
                            value: Number(e.target.value),
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="Amount/Ton"
                        variant="outlined"
                        type="number"
                        defaultValue={load.amount_ton}
                        onChange={(e) =>
                          updateLoad({
                            index,
                            key: "amount_ton",
                            value: Number(e.target.value),
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="Amount Total"
                        variant="outlined"
                        type="number"
                        defaultValue={load.amount_total}
                        onChange={(e) =>
                          updateLoad({
                            index,
                            key: "amount_total",
                            value: Number(e.target.value),
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="Vehicle Required"
                        variant="outlined"
                        type="number"
                        defaultValue={load.vehicle_required}
                        onChange={(e) =>
                          updateLoad({
                            index,
                            key: "vehicle_required",
                            value: Number(e.target.value),
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="Vehicle Capacity"
                        variant="outlined"
                        type="number"
                        defaultValue={load.vehicle_required_capacity_ton}
                        onChange={(e) =>
                          updateLoad({
                            index,
                            key: "vehicle_required_capacity_ton",
                            value: Number(e.target.value),
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      {load.min_cost as string}
                    </TableCell>
                    <TableCell align="left">
                      {load.max_cost as string}
                    </TableCell>
                    <TableCell align="left">
                      <Checkbox
                        checked={load.is_public}
                        onChange={(e) =>
                          updateLoad({
                            index,
                            key: "is_public",
                            value: e.target.checked,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Checkbox
                        checked={load.is_fraud}
                        onChange={(e) =>
                          updateLoad({
                            index,
                            key: "is_fraud",
                            value: e.target.checked,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Checkbox
                        checked={load.is_expired}
                        onChange={(e) =>
                          updateLoad({
                            index,
                            key: "is_expired",
                            value: e.target.checked,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell
                      className="pointer"
                      align="left"
                      onClick={() => {
                        copyLoadInfo(load);
                      }}
                    >
                      Copy
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default LoadDashboard;
