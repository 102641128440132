import { TextField } from "@mui/material";
import React from "react";
import { UpdateEntityFn } from "../types";
import { Address } from "../../../types";

export const AddressInfo = ({
  address,
  updateAddress,
  errorConfig,
}: {
  address: Address;
  updateAddress: UpdateEntityFn<Address>;
  errorConfig: { [key: string]: string };
}) => {
  return (
    <div>
      <div className="fs-16 mt-32 txt-align-center neutral-8">
        Transporter Address
      </div>
      <div className="d-flex  align-items-center mt-16">
        <TextField
          value={address.address_1}
          className=" bg-neutral-2 flex-grow "
          label="Address1"
          variant="outlined"
          onChange={(e) => updateAddress("address_1", e.target.value)}
          helperText={errorConfig['address_1']}
        />
      </div>
      <div className="d-flex  align-items-center mt-16">
        <TextField
          value={address.address_2}
          className=" bg-neutral-2 flex-grow "
          label="Address2"
          variant="outlined"
          onChange={(e) => updateAddress("address_2", e.target.value)}
        />
      </div>
      <div className="d-flex gap-24 mt-16">
        <TextField
          value={address.city}
          className=" bg-neutral-2 flex-grow"
          label="City"
          variant="outlined"
          onChange={(e) => updateAddress("city", e.target.value)}
          helperText={errorConfig['city']}
        />
        <TextField
          className=" bg-neutral-2 "
          label="State"
          variant="outlined"
          value={address.state}
          onChange={(e) => updateAddress("state", e.target.value)}
          helperText={errorConfig['state']}
        />
        <TextField
          className=" bg-neutral-2 "
          label="Pincode"
          variant="outlined"
          value={address.pincode}
          onChange={(e) => updateAddress("pincode", e.target.value)}
          helperText={errorConfig['pincode']}
        />
      </div>
    </div>
  );
};
