import { TextField } from "@mui/material";
import React from "react";
import { UpdateEntityFn } from "../types";
import { User } from "../../../types";

export const Contact = ({
  user,
  updateUser,
  errorConfig,
}: {
  user: Partial<User> | undefined;
  updateUser: UpdateEntityFn<User>;
  errorConfig: { [key: string]: string };
}) => {
  return (
    <div>
      <div className="fs-16 mt-32  txt-align-center neutral-8">User Info</div>
      <div className="d-flex gap-16 mt-24">
        <TextField
          className=" bg-neutral-2 "
          label="First Name"
          variant="outlined"
          value={user?.first_name}
          onChange={(e) => updateUser("first_name", e.target.value)}
          helperText={errorConfig["user_first_name"]}
        />
        <TextField
          className=" bg-neutral-2 "
          label="Last Name"
          variant="outlined"
          value={user?.last_name}
          onChange={(e) => updateUser("last_name", e.target.value)}
          helperText={errorConfig["user_last_name"]}
        />
      </div>
    </div>
  );
};
