import { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import { closeAlert, useAlert } from "../../../redux/slices/alertSlice";
import { useDispatch } from "react-redux";

const CustomAlert = () => {
  const { message, open, timeout } = useAlert();
  const dispatch = useDispatch();
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        dispatch(closeAlert());
      }, timeout);
      return () => clearTimeout(timer);
    }
  }, [dispatch, message, timeout]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      onClose={() => dispatch(closeAlert())}
      message={message}
    />
  );
};

export default CustomAlert;
