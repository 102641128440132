import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { ReduxStore } from "../types";

export interface EntitySlice {
  transport: boolean;
  load: boolean;
  address: boolean;
  review: boolean;
  sales_lead: boolean;
  call: boolean;
  trip: boolean;
}

const initialState: EntitySlice = {
  transport: false,
  load: false,
  address: false,
  review: false,
  sales_lead: false,
  call: false,
  trip: false
};

const entitySlice = createSlice({
  name: "entitySlice",
  initialState,
  reducers: {
    setAddEntity: (
      state,
      action: { payload: { key: keyof EntitySlice; value: boolean } }
    ) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const { setAddEntity } = entitySlice.actions;

export default entitySlice.reducer;

export const useEntity: () => EntitySlice = () =>
  useSelector((store: ReduxStore) => store.entity);
