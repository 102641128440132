import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../types';
import { Transport, User } from '../../components/types';

export interface ReviewData {
	rating: number;
	comment: string;
	transaction_amount: number;
	fraud_amount: number,	
}

export interface ReviewSlice {
	transport?: Transport,
	review: ReviewData,
	errorState: {[key: string]: string}
}
const initialState: ReviewSlice = {
	review: {
		rating: 4,
		comment: "",
		transaction_amount: 0,
		fraud_amount: 0,		
	},	
	errorState: {}
};

const reviewSlice = createSlice({
	name: 'review',
	initialState,
	reducers: {
		setTransport: (state, action) => {
			state.transport = action.payload;
		},
		setReview: (state, action) => {
			state.review = {
				...state.review,
				...action.payload
			}
		},
		setErrorState: (state, action) => {
			state.errorState = action.payload;
		},
	},
});

export const { setTransport, setReview, setErrorState } = reviewSlice.actions;

export default reviewSlice.reducer;

export const useReview: () => ReviewSlice = () => useSelector((state: ReduxStore) => state.review);	