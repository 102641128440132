import { Box, Modal } from '@mui/material'
import React from 'react'

export default function CustomModal({
    children,
    open,
    onClose,
    width,
    maxWidth,
    className
}: {
    open: boolean,
    onClose: () => void,
    children: React.JSX.Element[] | React.JSX.Element | React.ReactNode,
    width?: number | string,
    maxWidth?: string,
    className?: string
}) {
    return (
        <Modal open={open} onClose={onClose}>
            <Box className={className} sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: width || 400,
                maxWidth: maxWidth || "100%",
            }}>
                {children}
            </Box>
        </Modal>
    )
}
