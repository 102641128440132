import { useCallback, useEffect, useState } from "react";
import { Address, Load, Map } from "../../types";
import {
  UpdateEntityDebounceIndexFn,
  UpdateEntityFn,
} from "../../common/AddTransport/types";
import { listLoadApi, updateAddressApi, updateLoadApi } from "./api";
import { debounce } from "../../util/debounce";

export const useStateValues = () => {
  const [loads, setLoads] = useState<Load[]>([]);
  const [page, setPage] = useState(0);
  const [page_size, setPageSize] = useState(50);
  const [count, setCount] = useState(0);
  const [order_by, setOrderBy] = useState("-created");
  const [filter, setFilter] = useState<Map>({
    is_expired: false,
    is_public: false
  });
  const updateFilter: UpdateEntityFn<Map> = useCallback(
    (key, value) => {
      const newFilters = { ...filter };
      newFilters[key] = value;
      setFilter(newFilters);
    },
    [filter]
  );
  const getLoads = useCallback(() => {
    listLoadApi({
      ...filter,
      page: page + 1,
      page_size,
      order_by,
    }).then((data) => {
      setLoads(data.results);
      setCount(data.count);
    });
  }, [filter, order_by, page, page_size]);
  useEffect(() => {
    getLoads();
  }, [filter, getLoads]);
  const updateLoad: UpdateEntityDebounceIndexFn<Load> = ({
    index,
    key,
    value,
  }) => {
    const newLoads = [...loads];
    newLoads[index][key] = value;
    setLoads(newLoads);
    updateLoadApi(loads[index].id as number, {
      [key]: value,
    });
  };

  const updateAddress = ({
    index,
    key,
    type,
    value,
  }: {
    index: number;
    key: string;
    type: "origin" | "destination";
    value: string;
  }) => {
    const address = loads[index][type];
    if (value?.length) {
      updateAddressApi((address as Address)?.id as number, {
        [key]: value,
      });
    }
  };

  const updateSortKey = (sort_key: string) => {
    if (sort_key?.length) {
      if (order_by.includes(sort_key)) {
        if (order_by === sort_key) {
          setOrderBy(`-${sort_key}`);
        } else {
          setOrderBy(sort_key);
        }
      } else {
        setOrderBy(sort_key);
      }
    }
  };
  return {
    loads,
    setLoads,
    filter,
    updateFilter,
    page,
    setPage,
    page_size,
    setPageSize,
    count,
    updateLoad: debounce(updateLoad),
    updateAddress: debounce(updateAddress),
    order_by,
    updateSortKey,
  };
};
