import "./index.scss";
import Header from "../common/Header";
import LandingHero from "./LandingHero";
import ChooseUs from "./ChooseUs";
import Working from "./Working";
import Pricing from "./Pricing";
import Questions from "./Questions";
import Footer from "./Footer";
const Landing = () => {
  return (
    <div>
      <Header />
      <LandingHero />
      <ChooseUs />
      <Working />
      <Pricing/>
      <Questions/>
      <Footer />
    </div>
  );
};

export default Landing;
