import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ReviewsIcon from "@mui/icons-material/Reviews";
import { Phone } from "@mui/icons-material";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
export const NAV_BAR_ITEMS = [
  {
    url: "/admin",
    icon: TransferWithinAStationIcon,
    text: "Transporter",
  },
  {
    url: "/admin/transport-call",
    icon: Phone,
    text: "Call",
  },
  {
    url: "/admin/load",
    icon: LocalShippingIcon,
    text: "Load",
  },
  {
    url: "/admin/trip",
    icon: ModeOfTravelIcon,
    text: "Trip",
  },
  {
    url: "/admin/review",
    icon: ReviewsIcon,
    text: "Review",
  },
  {
    url: "/admin/sales",
    icon: AttachMoneyIcon,
    text: "Sales",
  },
];
export const ListItems = (props: { onClick: (e: any) => void }) => {
  return (
    <React.Fragment>
      {NAV_BAR_ITEMS.map((item, index) => (
        <ListItemButton
          key={index}
          sx={{
            backgroundColor: `${
              window.location.pathname === item.url ? "rgba(0, 0, 0, 0.2);" : ""
            }`,
          }}
          data-url={item.url}
          onClick={props.onClick}
        >
          <ListItemIcon>
            <item.icon />
          </ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItemButton>
      ))}
    </React.Fragment>
  );
};
