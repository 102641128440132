import React from 'react'

const Load = () => {
  return (
    <div>
      
    </div>
  )
};

export default Load
