import { useState } from "react";
import { Address, Transport, User } from "../../types";
import { UpdateEntityFn } from "./types";
import { isEmptyString } from "../../util/utils";

export const useStateValues = () => {
  const [errorConfig, setErrorConfig] = useState<{ [key: string]: string }>({});
  const validate = () => {
    const newErrorConfig: { [key: string]: string } = {};
    if (isEmptyString(transport.name)) {
      newErrorConfig["transport_name"] = "Empty transport name";
    }
    if (isEmptyString(transport.phone)) {
      newErrorConfig["transport_phone"] = "Empty transport phone";
    }
   
    if (isEmptyString(address.city)) {
      newErrorConfig["city"] = "Empty city";
    }
    if (isEmptyString(address.state)) {
      newErrorConfig["state"] = "Empty state";
    }   
    setErrorConfig(newErrorConfig);
    return Object.keys(newErrorConfig).length;
  };
  const [transport, setTransport] = useState<Transport>({
    id: null,
    name: "",
    email: "",
    phone: "",
  });

  const updateTransport: UpdateEntityFn<Transport> = (key, value) => {
    const new_transport = { ...transport };
    new_transport[key] = value;
    setTransport(new_transport);
  };

  const [address, setAddress] = useState<Address>({
    id: null,
    city: "",
    state: "",
    pincode: "00000",
    address_1: "",
    address_2: "",
    country: "IN",
  });
  const updateAddress: UpdateEntityFn<Address> = (key, value) => {
    const new_address = { ...address };
    new_address[key] = value;
    setAddress(new_address);
  };
  return {   
    transport,
    updateTransport,
    address,
    updateAddress,    
    errorConfig,
    validate
  };
};
