import axios, { AxiosInstance } from "axios";

export const getBaseUrl = () => {
  let baseApiUrl = "https://api.checkmytransport.com";
  if (window.location.href.includes("local")) {
    baseApiUrl = "http://localhost:8000";
  }

  return baseApiUrl;
};

export const getHeaders = () => {
  const token = localStorage.getItem("token");
  const headers: { [key: string]: string } = {};
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  return headers;
};

export const axiosInstance = (includeHeader = true) => {
  const instance = axios.create({
    baseURL: getBaseUrl(),
    headers: includeHeader ? getHeaders() : {},
    withCredentials: true,
  });
  interceptor(instance);
  return instance;
};

export const interceptor = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  instance.interceptors.request.use((config) => {
    return config;
  });
};
