import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function AccordionUsage() {
  const questions = [
    {
      question:
        "What documentation do I need to provide as a transporter or shipper?",
      answer:
        "You need to provide proof of identity and necessary licensing documentation.",
    },
    {
      question: "How do I post a load or find a carrier on these platforms?",
      answer:
        "To post a load or find a carrier on our transportation platform, simply create an account, list your load details, or search for available carriers.",
    },
    {
      question: "Can I leave reviews for carriers and transport services?",
      answer:
        "Yes, you can provide feedback and ratings to help maintain service quality and transparency.",
    },
    {
      question:
        "How do I become a registered transporter or shipper on your platform?",
      answer:
        "Sign up with necessary documentation, and start accessing transport opportunities and services.",
    },
  ];
  return (
    <div className="py-96 px-12 bg-neutral-11">
      <div className="py-16  font-weight-bold">
        <div className="fs-32 fe-600 txt-align-center">FAQ'S</div>
      </div>
      <div>
        {questions.map((question, index) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-head"
            >
              {question.question}
            </AccordionSummary>
            <AccordionDetails>{question.answer}</AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
}
