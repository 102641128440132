import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { LOGIN_VIEW_TYPE, LoginUser } from './types';
import { SocialLogin } from './SocialLogin';
import { User } from '../../types';
import { UpdateEntityFn } from '../AddTransport/types';
import { loginApi } from './api';
import { isEmptyString } from '../../util/utils';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../redux/slices/userSlice';



export const LoginPart = ({ setViewType, onClose }: { setViewType: (type: LOGIN_VIEW_TYPE) => void, onClose?: () => void }) => {
    const [errorState, setErrorState] = useState<{ [key: string]: string }>({});
    const [data, setData] = useState<LoginUser>({
        email: '',
        password: ''
    });
    const dispatch = useDispatch();

    const updateField: UpdateEntityFn<LoginUser> = (key, value) => {
        const newData = { ...data };
        newData[key] = value;
        setData(newData);
    };

    const login = () => {
        const newErrorState: { [key: string]: string } = {};
        if (isEmptyString(data.email)) {
            newErrorState['email'] = "Email Required";
        }
        if (isEmptyString(data.password)) {
            newErrorState['password'] = "Password Required";
        }
        setErrorState(newErrorState);
        if (Object.keys(newErrorState).length) {
            return;
        }
        loginApi(data).then(response => {
            const user_data = response.data;
            if (user_data.token) {
                dispatch(setUser(user_data));
                onClose?.();
            }
        }).catch((error) => {
            console.log('error', error);
        });
    };

    return (
        <div className='flex-col p-24'>
            <div className='fs-24 txt-align-center neutral-8 pointer '>Login </div>
            <div className='d-flex align-items-center mt-16'>
                <TextField
                    className='bg-neutral-2 flex-grow'
                    label="E-mail"
                    type='email'
                    variant="outlined"
                    value={data.email}
                    onChange={(e) => updateField('email', e.target.value)}
                    error={!!errorState.email}
                    helperText={errorState.email}
                />
            </div>
            <div className='d-flex align-items-center mt-16'>
                <TextField
                    className='bg-neutral-2 flex-grow'
                    label="Password"
                    type='password'
                    variant="outlined"
                    value={data.password}
                    onChange={(e) => updateField('password', e.target.value)}
                    error={!!errorState.password}
                    helperText={errorState.password}
                />
            </div>
            <div className='d-flex p-16 bg-orange-4 justify-content-center mt-16 pointer' onClick={login}>
                <span className='neutral-1 pointer'>Login</span>
            </div>
            <div className='pointer neutral-8 txt-align-center mt-16'>
                Forgot Password?
            </div>
            <div className='d-flex neutral-8 p-16 gap-8 justify-content-center'>
                <div> Not a member?<span className='blue-4 pointer' onClick={() => setViewType('SIGNUP')}> SIGN UP</span></div>
            </div>
            <SocialLogin />
        </div>
    );
};
