import { useState, useEffect } from "react";
import DenseTable from "./RedFlagList";
import BasicBars from "./BarGraph";
import image from "../../../assets/image.png";
import { DashboardData } from "../../common/Login/types";
import { axiosInstance } from "../../../api";


const LandingHero = () => {
  const [dashboardData, setDashboardData] = useState<DashboardData>();
  const [error, setError] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    axiosInstance()
      .get("/dashboard-data/")
      .then((response) => {
        console.log(response);
        setDashboardData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching dashboard data:", error);
        setError(error);
      });
  }, []);

  return (
    <div className="pt-96" style={{ position: "relative" }}>
      <div
        className=""
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundColor: "rgba(0, 0, 0, 0.9)",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          opacity: 0.15,
        }}
      ></div>
      <div className="p-16">
        <div className="txt-align-center">
          <div className="">
            <div className="fs-32 orange-4">
              Comprehensive Transport Solution
            </div>
            <div className="mt-16 fs-24">
              Empowering Decisions with Accurate and Up-to-Date Load
            </div>
          </div>
        </div>
        <div className="d-flex gap-16 mt-16 flex-wrap landing-cols">
          <div
            style={{ flexGrow: 1 }}
            className="f-col txt-align-center elevation-2 p-16 bg-orange-1 rounded-16 landing-table"
          >
            <div className="p-8 fs-18">
              Fraud Alert: Transporters to Watch
            </div>
            {dashboardData && dashboardData.blacklist && (
              <DenseTable data={dashboardData.blacklist} />
            )}
            <div className="fs-12 txt-align-right mt-16 ">
              <a
                className="green-700"
                style={{ textDecoration: "none" }}
                href="/"
              >
                &gt; View More
              </a>
            </div>
          </div>

          <div
            style={{ flexGrow: 1 }}
            className="txt-align-center elevation-2 p-16 bg-orange-1 rounded-16 landing-chart"
          >
            <div className="fs-18">
              Impact of Transport Fraud on Revenue (In Thousands)
            </div>
            <div className="d-flex">
              {dashboardData && (
                <BasicBars
                  transaction={dashboardData.transaction}
                  fraud={dashboardData.fraud}
                />
              )}
            </div>
          </div>
        </div>
        {error && <div className="error"> {error.message}</div>}
      </div>
    </div>
  );
};

export default LandingHero;
