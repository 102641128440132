import { axiosInstance } from "../../../api";
import { PaginatedResponse, Transport, Trip } from "../../types";
import { isEmpty } from "../../util/utils";

export const listTripApi = ({
  filter,
  order_by = "created",
  page = 1,
  page_size = 5,
}: {
  filter?: { [key: string]: unknown };
  order_by?: string;
  page?: number;
  page_size?: number;
}) => {
  console.log(filter);
  const urlParams = new URLSearchParams();
  urlParams.set("order_by", order_by);
  urlParams.set("page", String(page));
  urlParams.set("limit", String(page_size));
  for (let key in filter) {
    if (isEmpty(filter[key])) {
      continue;
    }
    urlParams.set(key, String(filter[key]));
  }

  return axiosInstance()
    .get<PaginatedResponse<Trip>>(
      `/backoffice/trip/list-detail/?page=1&${urlParams.toString()}`
    )
    .then((response) => response.data);
};

export const updateTripApi = (trip_id: number, data: Partial<Trip>) => {
  return axiosInstance()
    .patch<Transport>(`/backoffice/trip/${trip_id}/`, data)
    .then((response) => response.data);
};
