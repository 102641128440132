import { useCallback, useEffect, useState } from "react";
import { Address, Map, Transport, TransportDetail } from "../../types";
import {
  UpdateEntityDebounceFn,
  UpdateEntityDebounceIndexFn,
  UpdateEntityFn,
} from "../../common/AddTransport/types";
import { listTranportApi, updateTransportApi } from "./api";
import { debounce } from "../../util/debounce";
import { updateAddressApi } from "../AddAddress/api";

export const useStateValues = () => {
  const [loader, setLoader] = useState(false);
  const [transports, setTransports] = useState<TransportDetail[]>([]);
  const [page, setPage] = useState(0);
  const [page_size, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [order_by, setOrderBy] = useState("-created");
  const [filter, setFilter] = useState<Map>({
    is_active: true,
  });
  const updateFilter: UpdateEntityDebounceFn<Map> = useCallback(
    ({ key, value }) => {
      const newFilters = { ...filter };
      newFilters[key] = value;
      setFilter(newFilters);
    },
    [filter]
  );
  const getTransporters = useCallback(() => {
    setLoader(true);
    listTranportApi({
      filter,
      page: page + 1,
      page_size,
      order_by,
    }).then((data) => {
      setTransports(data.results);
      setCount(data.count);      
    }).finally(() => {
      setLoader(false);
    });
  }, [filter, order_by, page, page_size]);
  useEffect(() => {
    getTransporters();
  }, [filter, getTransporters]);
  const updateTransport: UpdateEntityDebounceIndexFn<Transport> = ({
    index,
    key,
    value,
  }) => {
    const newTransporters = [...transports];
    newTransporters[index][key] = value;
    setTransports(newTransporters);
    updateTransportApi(transports[index].id as number, {
      [key]: value,
    });
  };

  const updateAddress: UpdateEntityDebounceIndexFn<Address> = ({
    index,
    key,
    value,
  }) => {
    const address = transports[index].address;
    if (value) {
      updateAddressApi(address?.id as number, {
        [key]: value,
      });
    }
  };

  const updateSortKey = (sort_key: string) => {
    if (sort_key?.length) {
      if (order_by.includes(sort_key)) {
        if (order_by === sort_key) {
          setOrderBy(`-${sort_key}`);
        } else {
          setOrderBy(sort_key);
        }
      } else {
        setOrderBy(sort_key);
      }
    }
  };
  return {
    loader,
    transports,
    setTransports,
    filter,
    updateFilter: debounce(updateFilter),
    page,
    setPage,
    page_size,
    setPageSize,
    count,
    updateTransport: debounce(updateTransport),
    updateAddress: debounce(updateAddress),
    order_by,
    updateSortKey,
  };
};
