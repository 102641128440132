import React from 'react';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import VerifiedIcon from '@mui/icons-material/Verified';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AddModeratorIcon from '@mui/icons-material/AddModerator';

const ChooseUs = () => {
  const dataList = [
    {
      title: 'Explore Available Loads',
      description: 'Enables shippers to list loads, carriers to search, bid.',
      icon: LocalShippingIcon
    },
    {
      title: 'Ensure Reliable Transporters',
      description: 'Verify transporter credentials, licenses, certifications for compliance, reliability.',
      icon: AddModeratorIcon
    },
    {
      title: 'Validate Load Owner Information',
      description: "Verifies load owners' identities through secure processes.",
      icon: VerifiedIcon
    }
  ];
  return (
    <div className="px-12 pb-96 pt-8 bg-neutral-11">
      <div className="py-16  font-weight-bold">
        <div className='fs-32 fe-600 txt-align-center'>What Our Services Are?</div>
      </div>
      <div className="d-flex gap-32 mt-16 flex-wrap">
        {dataList.map((data, index) =>
          <div className="flex-col bg-neutral-1 p-32 rounded-16 elevation-2 justify-content-center align-items-center " style={{ flex: 1 }}>
            <div className="fs-24 fw-500 txt-alig txt-align-center">{data.title}</div>
            <data.icon className='mt-16 fs-64 orange-4' />
            <div className="flex-grow mt-32 neutral-7">
              <div className='fs-18 txt-align-center'>{data.description}</div>
            </div>
            <div className="d-flex mt-16 justify-content-center">
              <div className="pointer d-flex align-items-center gap-8 fs-32 fw-500 orange-4">More Details <ArrowCircleRightIcon /></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChooseUs;
